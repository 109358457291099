import {
  Address,
  CommercialReport,
  RawAddress,
  ReportResponse,
  SovOutput,
} from "../../client";

export type SafeSOV = Omit<SovOutput, "addresses"> & {
  addresses: RawAddress[];
  unsafeAddresses: Address[];
};
export type SafeCommercialReport = Omit<CommercialReport, "sovs"> & {
  sovs: SafeSOV[];
};
export type SafeReportResponse = Omit<ReportResponse, "report_json"> & {
  report_json: SafeCommercialReport;
};

export const applyOverridesToAddress = (address: Address): RawAddress => {
  // Probably common case: no overrides.
  if (Object.keys(address.private_overrides).length === 0) {
    return address.private_computed_values;
  }

  return Object.fromEntries(
    Object.entries(address.private_computed_values).map(([k, v]) => [
      k,
      address.private_overrides?.[k] ?? v,
    ])
  ) as RawAddress;
};

export const applyOverridesToSov = (sov: SovOutput): SafeSOV => {
  return {
    is_enabled: sov.is_enabled,
    filename: sov.filename,
    all_document_headers: sov.all_document_headers,
    column_mappings: sov.column_mappings,
    column_overrides: sov.column_overrides,
    addresses: sov.addresses.map(applyOverridesToAddress),
    unsafeAddresses: sov.addresses,
  };
};

export const applyOverridesToReport = (
  report: CommercialReport
): SafeCommercialReport => {
  return {
    report_id: report.report_id,
    appetite_score: report.appetite_score,
    html_map: report.html_map,
    naics_info: report.naics_info,
    env_risks: report.env_risks,
    loss_run: report.loss_run,
    company_info: report.company_info,
    layer_info: report.layer_info,
    risk_details: report.risk_details,
    deductible_info: report.deductible_info,
    due_date: report.due_date,
    business_classification: report.business_classification,
    ofac_result: report.ofac_result,
    news_info: report.news_info,

    sovs: report.sovs.map(applyOverridesToSov),
  };
};

export const applyOverridesToResponse = (
  response: ReportResponse
): SafeReportResponse => {
  return {
    report_id: response.report_id,
    email_subject: response.email_subject,
    created_at_ts: response.created_at_ts,
    modified_at_ts: response.modified_at_ts,
    human_review_info: response.human_review_info,
    assignee: response.assignee,
    status: response.status,
    report_json: applyOverridesToReport(response.report_json),
  };
};
