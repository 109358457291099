import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column, ColumnProps } from "primereact/column";
import * as client from "../../../client";
import { ExactMoney, formatAbbreviatedMoney } from "../Money";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import styles from "./AddressCard.module.css";
import { Menu } from "primereact/menu";
import { downloadSov } from "../../../api/routes";
import { EditSovModal } from "./EditSovModal";
import { ColumnMappingsModal } from "./ColumnMappingsModal";
import { PantheonOnly } from "../../../dev";
import { useUpdateReport } from "../data";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { applyOverridesToAddress } from "../Conversion";

type AddressCardProps = {
  specialtyPropertyInfo: client.ReportResponse;
  isCasualty?: boolean;
  setMapVisible?: (visible: boolean) => void;
};

// XXX very dangerous
const TerribleMoney = ({ dollars }: { dollars?: number }) => {
  if (dollars === undefined) {
    return <></>;
  }
  const cents = Math.round(dollars * 100);
  return <ExactMoney cents={cents} />;
};

const download = async (
  reportId: string,
  format: string,
  companyName: string
) => {
  // Download the zip file as a blob
  const blob = await downloadSov(reportId, format);

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element and trigger a download
  const link = document.createElement("a");
  link.href = url;
  link.download = `${companyName}_SOV_${format}.xlsx`;
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

type SovColumnProps = {
  getValue: (data: client.RawAddress) => string | number | undefined | null;
  columnProps: ColumnProps;
  style?: React.CSSProperties;
};

const columns: SovColumnProps[] = [
  {
    getValue: (data: client.RawAddress) => data.street_address,
    columnProps: { header: "Address" },
    style: { maxWidth: "200px" },
  },
  {
    getValue: (data: client.RawAddress) => data.city,
    columnProps: { header: "City" },
  },
  {
    getValue: (data: client.RawAddress) => data.state,
    columnProps: { header: "State", sortable: true },
  },
  {
    getValue: (data: client.RawAddress) => data.zip,
    columnProps: { header: "Zip" },
  },
  {
    getValue: (data: client.RawAddress) => data.country,
    columnProps: { header: "Country" },
  },
  {
    getValue: (data: client.RawAddress) =>
      `${data.occupancy_code_type} ${data.occupancy_code}`,
    columnProps: { header: "Occupancy" },
  },
  {
    getValue: (data: client.RawAddress) =>
      `${data.construction_code_type} ${data.construction_code}`,
    columnProps: { header: "Construction" },
  },
  {
    getValue: (data: client.RawAddress) => data.year_built,
    columnProps: { header: "Year Built" },
  },
  {
    getValue: (data: client.RawAddress) => data.number_of_stories,
    columnProps: { header: "Num Stories" },
  },
  {
    getValue: (data: client.RawAddress) =>
      data.building_value ? parseFloat(data.building_value) : undefined,
    columnProps: { header: "Building Value" },
  },
  {
    getValue: (data: client.RawAddress) =>
      data.bpp_value ? parseFloat(data.bpp_value) : undefined,
    columnProps: { header: "BPP Value" },
  },
  {
    getValue: (data: client.RawAddress) =>
      data.business_interruption_value
        ? parseFloat(data.business_interruption_value)
        : undefined,
    columnProps: { header: "BI Value" },
  },
  {
    getValue: (data: client.RawAddress) => data.tiv,
    columnProps: { header: "TIV", sortable: true },
  },
];

export const AddressCard: React.FC<AddressCardProps> = ({
  specialtyPropertyInfo,
  isCasualty,
  setMapVisible,
}) => {
  const reportId = specialtyPropertyInfo.report_id;
  const sovs = specialtyPropertyInfo.report_json.sovs;
  const companyName =
    specialtyPropertyInfo.report_json.company_info.company_name;

  // Show enabled addresses
  const addresses = useMemo(
    () =>
      sovs
        .filter((sov) => sov.is_enabled)
        .flatMap((sov) => sov.addresses.map(applyOverridesToAddress)),
    [sovs]
  );

  const totalTIV = useMemo(
    () => addresses.reduce((total, address) => total + address.tiv, 0),
    [addresses]
  );
  const totalBuildingValue = useMemo(
    () =>
      addresses.reduce(
        (total, address) =>
          total +
          (address.building_value && !isNaN(Number(address.building_value))
            ? Number(address.building_value)
            : 0),
        0
      ),
    [addresses]
  );
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [columnModalVisible, setColumnModalVisible] = useState(false);
  const updateReport = useUpdateReport(reportId);
  const isRerunning = updateReport.isPending;

  const [editModalActiveIndex, setEditModalActiveIndex] = useState<
    number | undefined
  >(undefined);
  const { tabInfo } = useParams<{ tabInfo?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (tabInfo) {
      if (tabInfo === "columns") {
        setColumnModalVisible(true);
      } else if (tabInfo === "construction") {
        setEditModalVisible(true);
        setEditModalActiveIndex(1);
      } else if (tabInfo === "occupancy" || tabInfo === "sheets") {
        setEditModalVisible(true);
        setEditModalActiveIndex(0);
      }
      navigate(
        ROUTES.DASHBOARD_REPORT(specialtyPropertyInfo.report_id, "sov"),
        { replace: true }
      );
    }
  }, [tabInfo]);

  return (
    <Card
      title="Statement of Values"
      subTitle={
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitleContentsContainer}>
            <span>
              {isCasualty
                ? `Total Building Value: ${formatAbbreviatedMoney(
                    totalBuildingValue
                  )}`
                : `Total Insured Value: ${formatAbbreviatedMoney(totalTIV)}`}
            </span>
          </div>
          <div className={styles.subtitleContentsContainer}>
            {setMapVisible ? (
              <Button
                text
                raised
                severity="secondary"
                icon="pi pi-map"
                label="Show map"
                onClick={() => setMapVisible(true)}
              />
            ) : null}
            <DownloadActionMenu
              onDownloadRMS={() => download(reportId, "rms", companyName)}
              onDownloadHX={() => download(reportId, "hx", companyName)}
            />
            <Button
              text
              raised
              label="Edit"
              severity="secondary"
              icon="pi pi-file-edit"
              onClick={() => setEditModalVisible(true)}
            />
            {sovs.some(
              (sov) => sov.column_mappings && sov.all_document_headers
            ) && (
              <Button
                text
                raised
                label="Columns"
                severity="secondary"
                icon="pi pi-arrow-right"
                onClick={() => setColumnModalVisible(true)}
              />
            )}
            <PantheonOnly>
              <Button
                text
                raised
                label="Re-process"
                severity="danger"
                icon={isRerunning ? "pi pi-spin pi-spinner" : "pi pi-replay"}
                onClick={() => updateReport.mutate({ rerunReport: true })}
                disabled={isRerunning}
              />
            </PantheonOnly>
          </div>
        </div>
      }
    >
      <DataTable
        value={addresses}
        paginator
        rows={100}
        className={styles.table}
      >
        {columns.map((columnInfo, index) => (
          <Column
            key={index}
            {...columnInfo.columnProps}
            style={columnInfo.style}
            body={(data: client.RawAddress) => {
              const value = columnInfo.getValue(data);
              if (typeof value === "number") {
                return <TerribleMoney dollars={value} />;
              }
              return <span>{value}</span>;
            }}
          />
        ))}
        {[
          {
            name: "Flood Zone",
            field: "flood_zone",
            get: (data: client.RawAddress) => data.flood_zone,
          },
          {
            name: "AOP Ded (% TIV)",
            field: "aop_deductible",
            get: (data: client.RawAddress) => data.aop_deductible,
          },
          {
            name: "Fld Ded (% TIV)",
            field: "flood_deductible",
            get: (data: client.RawAddress) => data.flood_deductible,
          },
          {
            name: "EQ Ded (% TIV)",
            field: "eq_deductible",
            get: (data: client.RawAddress) => data.eq_deductible,
          },
          {
            name: "NS Ded (% TIV)",
            field: "named_storm_deductible",
            get: (data: client.RawAddress) => data.named_storm_deductible,
          },
        ].map(({ field, name, get }) => (
          <Column
            key={field}
            sortable
            field={field}
            header={name}
            body={(data: client.RawAddress) => {
              const val = get(data);
              if (typeof val === "number") {
                return (val * 100).toFixed(1) + "%";
              }
              return val;
            }}
          />
        ))}
      </DataTable>
      <EditSovModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        sovs={sovs}
        reportId={reportId}
        initialActiveIndex={editModalActiveIndex}
      />
      <ColumnMappingsModal
        visible={columnModalVisible}
        setVisible={setColumnModalVisible}
        sovs={sovs}
        reportId={reportId}
      />
    </Card>
  );
};

type DownloadActionMenuProps = {
  onDownloadRMS: () => void;
  onDownloadHX: () => void;
};

const DownloadActionMenu: React.FC<DownloadActionMenuProps> = ({
  onDownloadRMS,
  onDownloadHX,
}) => {
  const menu = useRef<Menu>(null);
  const items = [
    {
      label: "Download",
      items: [
        {
          label: "RMS Sheet",
          command: onDownloadRMS,
        },
        {
          label: "HX Sheet",
          command: onDownloadHX,
        },
      ],
    },
  ];

  return (
    <div>
      <Menu model={items} popup ref={menu} />
      <Button
        text
        raised
        label="Download"
        severity="secondary"
        icon="pi pi-file-excel"
        onClick={(e) => {
          menu.current?.toggle(e);
        }}
      />
    </div>
  );
};
