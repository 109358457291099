import { Dialog } from "primereact/dialog";
import styles from "./AppetiteModal.module.css";
import { AppetiteIcon } from "./AppetiteIcon";
import classNames from "classnames";
import { useContext } from "react";
import { ThemeContext } from "../../../themes/Theme";
import { motion } from "framer-motion";
import * as client from "../../../client";

const NUM_COLS = 6;

type AppetiteModalProps = {
  report: {
    business_classification?: client.BusinessClassification | null;
    appetite_score: number;
  };
  visible: boolean;
  onHide: () => void;
};

const selectedIndexForAppetiteScore = (
  majorIndustryIndex: number | undefined,
  minorIndustryIndex: number | undefined,
  appetiteScore: number
) => {
  // Get the correct grid index, accounting for a fixed number of columns and the header row
  if (majorIndustryIndex !== undefined && minorIndustryIndex !== undefined) {
    return NUM_COLS * (majorIndustryIndex + 1) + minorIndustryIndex + 1;
  }

  // Fallbacks for existing reports with no major industry index
  if (appetiteScore === 1) {
    return 11;
  } else if (appetiteScore === 2) {
    return 52;
  } else if (appetiteScore === 3) {
    return 15;
  } else if (appetiteScore === 4) {
    return 26;
  }
  return 7;
};

export const AppetiteModal: React.FC<AppetiteModalProps> = ({
  report,
  visible,
  onHide,
}) => {
  const selectedIndex = selectedIndexForAppetiteScore(
    report.business_classification?.major_industry_index,
    report.business_classification?.minor_industry_index,
    report.appetite_score
  );
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  return (
    <Dialog header="Appetite" visible={visible} onHide={onHide}>
      <div className={styles.gridContainer}>
        {gridText.map((text, index) => (
          <motion.div
            initial={{ scale: 1.0 }}
            animate={{ scale: index === selectedIndex ? 1.1 : 1.0 }}
            transition={{
              delay: 0.32,
              duration: 0.65,
              type: "spring",
              bounce: 0.4,
            }}
            key={index}
            className={classNames(
              styles.gridItem,
              index > 0 && index < 6 && styles.gridItemAppetiteHeader,
              (index < 6 || index === selectedIndex) && styles.gridItemBold,
              index === 0 &&
                (isLightMode
                  ? styles.gridItemTopLeft
                  : styles.gridItemTopLeftDark),
              index !== 0 &&
                index % 6 === 0 &&
                (isLightMode
                  ? styles.gridItemLeftCol
                  : styles.gridItemLeftColDark),
              index === selectedIndex && styles.gridItemSelected
            )}
          >
            {index > 0 && index < 6 ? (
              <AppetiteIcon appetite={6 - index} size="large" fontSize="13px" />
            ) : (
              <span
                style={{
                  zIndex: "10",
                }}
              >
                {text}
              </span>
            )}
          </motion.div>
        ))}
      </div>
    </Dialog>
  );
};

export const MAJOR_INDUSTRIES = [
  "Manufacturers / Industrial Processing",
  "Dealers / Distributors",
  "Contractors / Construction",
  "Real Estate (excluding Habitational)",
  "Retail / Mercantile",
  "Service",
  "Miscellaneous",
  "Hospitality (no liquor operations)",
  "Hospitality (liquor operations)",
  "Habitational",
];

const gridText = [
  "Major Industry Group",
  "Highly Desirable / Target",
  "Desirable / Build",
  "Opportunistic / Strategic",
  "Restricted / Limit",
  "Out-of-Appetite",

  "Manufacturers / Industrial Processing",
  "Commercial / Industrial; Metal Goods products; Machine Shops",
  "Automotive parts (non-critical); Valve products",
  "Consumer; Sporting Goods products",
  "Industrial Chemical products",
  "Aircraft / Aerospace; Cannabis; Tobacco; Firearms; Pharmaceutical products",

  "Dealers / Distributors",
  "Appliance; Air Conditioning; Janitorial",
  "Machinery / Equipment (new); Beverage",
  "Machinery / Equipment (used); Sporting Goods",
  "Chemical; Fuel; Motor Vehicles",
  "Any product deemed out-of-appetite above",

  "Contractors / Construction",
  "Commercial / Industrial specialty trades;",
  "Metal Erection - Decorative; Commercial Roofing",
  "Metal Erection - Structural; Residential Roofing",
  "",
  "Risk domiciled in or doing any work in NY; Pipeline",

  "Real Estate (excluding Habitational)",
  "Office buildings; Commercial / Retail / Mercantile LROs",
  "Commercial Occupancy Condominiums; Vacant buildings; Vacant Land",
  "Sporting Goods",
  "",
  "",

  "Retail / Mercantile",
  "Automobile Parts",
  "",
  "",
  "",
  "Cannabis",

  "Service",
  "",
  "",
  "",
  "Adventure tours / outfitters",
  "Risks with PL exposures and no PL coverage",

  "Miscellaneous",
  "Truckers GL (primary only)",
  "",
  "Other",
  "Adventure activity operations",
  "Energy / Utilities; Public Entities; Railroads",

  "Hospitality (no liquor operations)",
  "",
  "Low-Rise Hotels with limited amenities",
  "Motels",
  "Large resorts with extensive amenities",
  "Trampoline / Inflatable device centers",

  "Hospitality (liquor operations)",
  "",
  "Restaurants with <30% liquor; Risks with up to 50% liquor",
  "Risks with >50% liquor",
  "Risks with closing time after 12pm",
  ">75% liquor; Adult entertainment; Large entertainment venues",

  "Habitational",
  "",
  "Single-family rental dwellings",
  "Condominiums; Mobile home parks;",
  "Large schedules; Risks in FL",
  "Student or HUD housing > 25%",
];
