import { InputText } from "primereact/inputtext";
import * as client from "../../../client";
import { useMemo, useState } from "react";
import { useAddressSearch } from "../data";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AbbreviatedMoney } from "../Money";
import styles from "./SearchAddresses.module.css";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";

type RowData = {
  name: string;
  report: client.ReportMetadataResponse;
  tiv: number;
  addresses: client.RawAddress[];
};
export const SearchAddresses = ({
  specialtyReports,
}: {
  specialtyReports: client.ReportMetadataResponse[];
}) => {
  const [value, setValue] = useState("");
  const [query, setQuery] = useState("");
  const results = useAddressSearch(query, []);
  const rows = useMemo(
    () =>
      Object.entries(results.data?.by_report ?? {})
        .map(([reportId, addresses]): RowData | undefined => {
          const report = specialtyReports.find((r) => r.report_id === reportId);
          if (report) {
            return {
              name: report.report_metadata.company_info.company_name,
              report,
              tiv: addresses.reduce((tiv, a) => tiv + a.tiv, 0),
              addresses,
            };
          }
        })
        .filter((r): r is RowData => r !== undefined),
    [results.data, specialtyReports]
  );

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const doRawSearch = () => {
    setQuery(value);
    setExpandedRows([]);
  };

  return (
    <div>
      <div>
        <div className={styles.search + " p-inputgroup"}>
          <InputText
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doRawSearch();
              }
            }}
            onInput={(e) => setValue(e.currentTarget.value)}
            value={value}
            placeholder="123 Main St"
            style={{ width: "100%" }}
          />
          <Button onClick={doRawSearch} disabled={value === query}>
            Search
          </Button>
        </div>
      </div>
      {results.isFetching ? (
        <DataTable
          key="fetching"
          scrollable
          scrollHeight="calc(100vh - 160px)"
          dataKey="id"
          value={[{ id: "1" }, { id: "2" }, { id: "3" }]}
          style={{ marginLeft: "24px", marginRight: "24px" }}
        >
          <Column expander />
          <Column
            header="Company"
            sortable
            body={() => <Skeleton width="300px" height="24px" />}
          />
          <Column
            header="Value"
            body={() => <Skeleton width="60px" height="24px" />}
          />
          <Column
            header="Locations"
            body={() => <Skeleton width="32px" height="24px" />}
          />
        </DataTable>
      ) : results.isError ? (
        "Sorry, a failure occurred. Try again later."
      ) : (
        <DataTable
          key="results"
          scrollable
          scrollHeight="calc(100vh - 160px)"
          dataKey="report.report_id"
          value={rows}
          style={{ marginLeft: "24px", marginRight: "24px" }}
          expandedRows={Object.fromEntries(
            expandedRows.map((id) => [id, true])
          )}
          onRowExpand={(e) =>
            setExpandedRows((ids) => ids.concat([e.data.report.report_id]))
          }
          onRowCollapse={(e) =>
            setExpandedRows((ids) =>
              ids.filter((id) => id != e.data.report.report_id)
            )
          }
          rowExpansionTemplate={(data: RowData) => (
            <div className="p-3">
              <DataTable value={data.addresses}>
                <Column
                  header="TIV"
                  field="tiv"
                  body={(d: client.RawAddress) => (
                    <AbbreviatedMoney dollars={d.tiv} />
                  )}
                />
                <Column header="Address" field="street_address" />
                <Column header="County" field="county" />
                <Column header="City" field="city" />
                <Column header="State" field="state" />
                <Column header="Zip" field="zip" />
              </DataTable>
            </div>
          )}
        >
          <Column expander />
          <Column header="Company" field="name" sortable />
          <Column
            header="Value"
            field="tiv"
            body={(d: RowData) => <AbbreviatedMoney dollars={d.tiv} />}
          />
          <Column header="Locations" field="addresses.length" />
        </DataTable>
      )}
    </div>
  );
};
