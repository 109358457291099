import { InputText } from "primereact/inputtext";
import styles from "./Dashboard.module.css";
import { useMemo, useRef, useState } from "react";
import { ReportStatus } from "../../../api/types";
import { SpecialtyReport } from "../SpecialtyReport";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import {
  DashboardHeaderRow,
  DashboardLoadingRows,
  DashboardRow,
  DashboardRowBreak,
} from "./DashboardRow";
import { Dropdown } from "primereact/dropdown";
import {
  appetiteScoreForReportInfo,
  brokerageFromBroker,
  readableStatus,
  TEMP_PIPELINE_EXAMPLES,
} from "./util";
import { useReports, useUsers } from "../data";
import * as client from "../../../client";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { AppetiteModal } from "../appetite/AppetiteModal";
import { DashboardAnalytics } from "./Analytics";
import { PantheonAvatar } from "../../../components/avatar/Avatar";
import { UseQueryResult } from "@tanstack/react-query";
import { displayNameForUser } from "../../../util";
import { SearchAddresses } from "./SearchAddresses";
import { Menu } from "primereact/menu";

type SortType =
  | "recency_newest"
  | "recency_oldest"
  | "appetite_highest"
  | "appetite_lowest";

type StatusOption = {
  name: string;
  code: ReportStatus;
};

type UserOption = { name: string; id: string };

export const Dashboard = () => {
  const apiReports = useReports();
  const specialtyReports: client.ReportMetadataResponse[] = useMemo(
    () => (apiReports.isSuccess ? apiReports.data : []),
    [apiReports.isSuccess, apiReports.data]
  );

  return (
    <Routes>
      <Route
        path="report/:id/:tabPath?/:tabInfo?"
        element={<SpecialtyReport />}
      />
      <Route
        path="analytics"
        element={<DashboardAnalytics specialtyReports={specialtyReports} />}
      />
      <Route
        path="search-addresses"
        element={<SearchAddresses specialtyReports={specialtyReports} />}
      />
      <Route
        path="reports/casualty"
        element={
          <Reports
            isCasualty={true}
            apiReports={apiReports}
            specialtyReports={specialtyReports}
          />
        }
      />
      <Route
        path="reports/property"
        element={
          <Reports
            isCasualty={false}
            apiReports={apiReports}
            specialtyReports={specialtyReports}
          />
        }
      />
      <Route path="*" element={<Navigate to={ROUTES.DASHBOARD_PROPERTY} />} />
    </Routes>
  );
};

type ReportsProps = {
  apiReports: UseQueryResult<client.ReportMetadataResponse[], Error>;
  specialtyReports: client.ReportMetadataResponse[];
  isCasualty: boolean;
};

const Reports: React.FC<ReportsProps> = ({
  apiReports,
  specialtyReports,
  isCasualty,
}) => {
  const [searchText, setSearchText] = useState("");
  const [sortType, setSortType] = useState<SortType>("appetite_highest");
  const [selectedAssigneeUsers, setSelectedAssigneeUsers] = useState<
    UserOption[]
  >([]);
  const [selectedStatuses, setSelectedStatuses] = useState<StatusOption[]>([]);
  const statuses = Object.values(ReportStatus).map((status) => {
    return {
      name: readableStatus(status),
      code: status,
    };
  });

  const [
    selectedReportIdForBusinessClassification,
    setSelectedReportIdForBusinessClassification,
  ] = useState<string | undefined>(undefined);
  const selectedReportForBusinessClassification = specialtyReports.find(
    (report) => report.report_id === selectedReportIdForBusinessClassification
  );

  const navigate = useNavigate();

  const filteredAndSortedReports = useMemo(() => {
    const filteredReports = specialtyReports.filter((report) => {
      if (selectedStatuses.length > 0) {
        if (!selectedStatuses.some((status) => status.code === report.status)) {
          return false;
        }
      }

      if (selectedAssigneeUsers.length > 0) {
        if (
          !selectedAssigneeUsers.some(
            (user) =>
              (user.id === "Unassigned" && !report.assignee) ||
              user.id === report.assignee
          )
        ) {
          return false;
        }
      }

      if (searchText !== "") {
        const searchTextLower = searchText.toLowerCase();
        if (
          // TODO: what else should we search on?
          !report.report_metadata.company_info.company_name
            .toLowerCase()
            .includes(searchTextLower) &&
          !brokerageFromBroker(report.report_metadata.company_info.broker_name)
            .toLowerCase()
            .includes(searchTextLower)
        ) {
          return false;
        }
      }

      // TODO: add a flag to the backend for casualty status
      if (
        (report.report_metadata.company_info.company_name ==
          "Lake Forest II") !=
        isCasualty
      ) {
        return false;
      }

      return true;
    });

    filteredReports.sort((a, b) => {
      const appetiteScoreA = appetiteScoreForReportInfo(
        a.report_metadata.business_classification,
        a.report_metadata.appetite_score
      );
      const appetiteScoreB = appetiteScoreForReportInfo(
        b.report_metadata.business_classification,
        b.report_metadata.appetite_score
      );

      const createdAtA = new Date(a.created_at_ts).getTime();
      const createdAtB = new Date(b.created_at_ts).getTime();

      if (sortType === "appetite_highest") {
        if (appetiteScoreB === appetiteScoreA) {
          return createdAtB - createdAtA;
        }
        return appetiteScoreB - appetiteScoreA;
      } else if (sortType === "appetite_lowest") {
        if (appetiteScoreB === appetiteScoreA) {
          return createdAtB - createdAtA;
        }
        return appetiteScoreA - appetiteScoreB;
      } else if (sortType === "recency_newest") {
        return createdAtB - createdAtA;
      }
      return createdAtA - createdAtB;
    });

    return filteredReports;
  }, [
    sortType,
    selectedStatuses,
    specialtyReports,
    searchText,
    selectedAssigneeUsers,
    isCasualty,
  ]);

  const renewalReports = filteredAndSortedReports.filter((report) =>
    TEMP_PIPELINE_EXAMPLES.has(report.report_metadata.company_info.company_name)
  );
  const mainReports = filteredAndSortedReports.filter(
    (report) =>
      !TEMP_PIPELINE_EXAMPLES.has(
        report.report_metadata.company_info.company_name
      )
  );
  const menuRef = useRef<Menu>(null);

  return (
    <>
      <div className={styles.searchContainer}>
        <InputText
          className={styles.searchInput}
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className={styles.subSearchContainer}>
          <div className={styles.filterSortContainer}>
            <div className={styles.chipContainer}>
              <StatusFilterDropdown
                statuses={statuses}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
              />
            </div>
            <div className={styles.chipContainer}>
              <AssigneeFilterDropdown
                selectedUsers={selectedAssigneeUsers}
                setSelectedUsers={setSelectedAssigneeUsers}
              />
            </div>
            <div className={styles.chipContainer}>
              <SortByDropdown setRecencySortType={setSortType} />
            </div>
          </div>
          <Menu
            model={[
              {
                label: "Analytics",
                icon: "pi pi-chart-line",
                command: () => navigate(ROUTES.DASHBOARD_ANALYTICS),
              },
              {
                label: "Address Search",
                icon: "pi pi-bullseye",
                command: () => navigate(ROUTES.DASHBOARD_ADDRESS_SEARCH),
              },
            ]}
            popup
            ref={menuRef}
            id="popup_menu"
            popupAlignment="left"
          />
          <Button
            label="More Tools"
            rounded
            className={styles.chipButton}
            icon="pi pi-ellipsis-h"
            onClick={(event) => menuRef.current?.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
          />
        </div>
        <div className={styles.rowsContainer}>
          <DashboardHeaderRow />
          {apiReports.isPending ? (
            <DashboardLoadingRows />
          ) : (
            <>
              {renewalReports.length > 0 && (
                <DashboardRowBreak title="Upcoming Policy Renewals" />
              )}
              {renewalReports.map((report) => (
                <DashboardRow
                  key={report.report_id}
                  specialtyInfo={report}
                  isUpcomingRenewal={true}
                  onClick={() =>
                    navigate(
                      ROUTES.DASHBOARD_REPORT(report.report_id, "summary")
                    )
                  }
                  setReportIdForBusinessClassification={(id) =>
                    setSelectedReportIdForBusinessClassification(id)
                  }
                />
              ))}
              {mainReports.length > 0 && (
                <DashboardRowBreak title="Recent Submissions" />
              )}
              {mainReports.map((report) => (
                <DashboardRow
                  key={report.report_id}
                  isUpcomingRenewal={false}
                  specialtyInfo={report}
                  onClick={() =>
                    navigate(
                      ROUTES.DASHBOARD_REPORT(report.report_id, "summary")
                    )
                  }
                  setReportIdForBusinessClassification={(id) =>
                    setSelectedReportIdForBusinessClassification(id)
                  }
                />
              ))}
            </>
          )}
        </div>
      </div>
      {selectedReportForBusinessClassification && (
        <AppetiteModal
          report={selectedReportForBusinessClassification.report_metadata}
          visible={selectedReportForBusinessClassification !== undefined}
          onHide={() => setSelectedReportIdForBusinessClassification(undefined)}
        />
      )}
    </>
  );
};

type SortByDropdownProps = {
  setRecencySortType: (sortType: SortType) => void;
};

const SortByDropdown: React.FC<SortByDropdownProps> = ({
  setRecencySortType,
}) => {
  const [selectedSortByOption, setSelectedSortByOption] = useState(null);
  const sortOptions = [
    { name: "Recency - newest to oldest", code: "recency_newest" },
    { name: "Recency - oldest to newest", code: "recency_oldest" },
    { name: "Appetite - highest to lowest", code: "appetite_highest" },
    { name: "Appetite - lowest to highest", code: "appetite_lowest" },
  ];

  return (
    <Dropdown
      value={selectedSortByOption}
      onChange={(e) => {
        setSelectedSortByOption(e.value);
        setRecencySortType(e.value.code);
      }}
      options={sortOptions}
      optionLabel="name"
      placeholder="Sort by"
      className={styles.multiSelect}
    />
  );
};

type StatusFilterDropdownProps = {
  statuses: StatusOption[];
  selectedStatuses: StatusOption[];
  setSelectedStatuses: (statuses: StatusOption[]) => void;
};

const StatusFilterDropdown: React.FC<StatusFilterDropdownProps> = ({
  statuses,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  return (
    <MultiSelect
      value={selectedStatuses}
      onChange={(e) => setSelectedStatuses(e.value)}
      options={statuses}
      optionLabel="name"
      placeholder={"Status"}
      maxSelectedLabels={3}
      className={styles.multiSelect}
    />
  );
};

type AssigneeFilterDropdownProps = {
  selectedUsers: UserOption[];
  setSelectedUsers: (ids: UserOption[]) => void;
};

const avatarTemplate = ({ name }: { name: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <PantheonAvatar label={name === "Unassigned" ? undefined : name} />
      <span>{name === "Unassigned" ? "Unassigned" : name}</span>
    </div>
  );
};

const AssigneeFilterDropdown: React.FC<AssigneeFilterDropdownProps> = ({
  selectedUsers,
  setSelectedUsers,
}) => {
  const users = useUsers();
  const options = [
    ...(users?.data?.all_company_users ?? []).map((u) => ({
      name: displayNameForUser(u),
      id: u.id,
    })),
    { name: "Unassigned", id: "Unassigned" },
  ];

  return (
    <MultiSelect
      value={selectedUsers}
      onChange={(e) => setSelectedUsers(e.value)}
      options={options}
      itemTemplate={avatarTemplate}
      optionLabel="name"
      placeholder={"Assignee"}
      className={styles.multiSelect}
    />
  );
};
