import { Card } from "primereact/card";
import styles from "./FinancialCard.module.css";
import { useContext, useRef } from "react";
import { ThemeContext } from "../../../themes/Theme";
import { motion } from "framer-motion";
import { OverlayPanel } from "primereact/overlaypanel";
import {
  Severity,
  colorForAttributes,
  borderColorForAttributes,
} from "../dashboard/util";
import { SafeReportResponse } from "../Conversion";

type FinancialCardProps = {
  specialtyPropertyInfo: SafeReportResponse;
};

const HARDCODED_LAKE_FOREST_II_FINANCIALS = {
  requestId: "06494d94-7e86-45c5-a077-f5031841d7aa",
  submissionId: "06494d94-7e86-45c5-a077-f5031841d7aa",
  status: "Success",
  matchDescription: "Default match logic",
  addressDescription: "Address verified",
  name: "LAKE FOREST II DOLPHINS SWIM CLUB",
  address: {
    addressLine1: "24752 TOLEDO LN",
    city: "LAKE FOREST",
    state: "CA",
    zipCode: "92630",
    addressType: "Street Address",
  },
  primaryNAICSCode: "611620",
  otherNAICSCodes: ["813910", "813319", "813990"],
  failRate: 1,
  failLevel: 1,
  failReasons: [],
  creditScore: 393,
  creditClass: 4,
  creditPercentile: 23,
  creditReasons: [
    "Insufficient Information on or Lack of Non-Financial Accounts",
    "Number of Non-Financial Trades",
    "Percent Non-Financial Trade Utilization in Lasts 48 Months",
    "Geographical Region",
  ],
  bankruptcy: "N",
};

export const FinancialCard: React.FC<FinancialCardProps> = () => {
  return (
    <Card title="Finances">
      <div className={styles.financialInfoContainer}>
        <div className={styles.financialInfoRow}>
          <span className={styles.financialInfoHeader}>{"Fail Rate"}</span>
          <FinancialInfoPiece
            title={`${HARDCODED_LAKE_FOREST_II_FINANCIALS["failRate"]} / 9`}
            subtitle={"FAIL RATE"}
            severity={"good"}
            overlayText="A score estimating the likelihood of the business failing (due to formal or informal bankruptcy) in the next year, returned as a value in the range of 1 through 9, with a 9 indicating the highest risk. A 0 indicates that the company has filed for bankruptcy."
          />
          <FinancialInfoPiece
            title={`${HARDCODED_LAKE_FOREST_II_FINANCIALS["failLevel"]} / 3`}
            subtitle={"FAIL LEVEL"}
            severity={"good"}
            overlayText="A score estimating the likelihood of the business failing (due to formal or informal bankruptcy) in the next year, returned as a 1, 2, or 3, where a 1 equates to the lowest risk, a 2 indicates marginal risk and a 3 indicates high risk. A 0 indicates that the company has filed for bankruptcy."
          />
        </div>
      </div>
      <div className={styles.financialInfoContainer}>
        <div className={styles.financialInfoRow}>
          <span className={styles.financialInfoHeader}>{"Credit"}</span>
          <FinancialInfoPiece
            title={`${HARDCODED_LAKE_FOREST_II_FINANCIALS["creditScore"]}`}
            subtitle={"CREDIT SCORE"}
            severity={"medium"}
            overlayText="A score estimating the likelihood of the business becoming severely delinquent (90 days or more past due) or charge-off within the next year. The score ranges from 101 to 660, with a 660 indicating the lowest risk. A 0 indicates that the business has filed for bankruptcy."
          />
          <FinancialInfoPiece
            title={`${HARDCODED_LAKE_FOREST_II_FINANCIALS["creditClass"]} / 5`}
            subtitle={"CREDIT CLASS"}
            severity={"bad"}
            overlayText="A score estimating the likelihood of the business becoming severely delinquent (90 days or more past due) or charge-off within the next year, returned as a value in the range of 1 through 5, with a 5 indicating the highest risk. A 0 indicates that the business has filed for bankruptcy."
          />
          <FinancialInfoPiece
            title={`${HARDCODED_LAKE_FOREST_II_FINANCIALS["creditPercentile"]}%`}
            subtitle={"CREDIT PERCENTILE"}
            severity={"bad"}
            overlayText="The percentile of the estimated business's risk of becoming severely delinquent (91 or more days past due) relative to the population of businesses in the Commercial database. Returns a value in the range of 1 through 100, a 1 indicating that the business is among the top 1% of highest risk businesses for becoming severely delinquent, and a 100 indicating that the business is in 1% of businesses at the lowest risk. A 997 is returned when no record was found, a 998 indicates bankruptcy, and a 999 indicates that no score was able to be calculated."
          />
        </div>
        {HARDCODED_LAKE_FOREST_II_FINANCIALS["creditReasons"].length > 0 && (
          <div className={styles.reasonsContainer}>
            {HARDCODED_LAKE_FOREST_II_FINANCIALS["creditReasons"].map(
              (reason, index) => (
                <div className={styles.roundedContainerSmall} key={index}>
                  <span>{reason}</span>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

type FinancialInfoPieceProps = {
  title: string;
  subtitle: string;
  severity: Severity;
  overlayText: string;
};

const FinancialInfoPiece: React.FC<FinancialInfoPieceProps> = ({
  title,
  subtitle,
  severity,
  overlayText,
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const op = useRef<OverlayPanel>(null);

  return (
    <>
      <motion.div
        tabIndex={0}
        role="button"
        whileHover={{ scale: 1.05 }}
        whileFocus={{ scale: 1.05 }}
        className={styles.roundedContainer}
        style={{
          background: colorForAttributes(severity, isLightMode),
          borderColor: borderColorForAttributes(severity, isLightMode),
        }}
        onClick={(e) => op.current?.toggle(e)}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            op.current?.toggle(event);
          }
        }}
      >
        <span className={styles.boldNumber}>{title}</span>
        <span className={styles.containerText}>{subtitle}</span>
        <OverlayPanel ref={op} style={{ maxWidth: "600px" }}>
          <span>{overlayText}</span>
        </OverlayPanel>
      </motion.div>
    </>
  );
};
