import { MAJOR_INDUSTRIES } from "./appetite/AppetiteModal";
import styles from "./CompanyInfoHeader.module.css";
import { SafeReportResponse } from "./Conversion";
import { brokerageFromBroker } from "./dashboard/util";

type CompanyInfoHeaderProps = {
  specialtyPropertyInfo: SafeReportResponse;
};

export const CompanyInfoHeader: React.FC<CompanyInfoHeaderProps> = ({
  specialtyPropertyInfo,
}) => {
  const industry =
    specialtyPropertyInfo.report_json.business_classification
      ?.major_industry_index !== undefined
      ? MAJOR_INDUSTRIES[
          specialtyPropertyInfo.report_json.business_classification
            .major_industry_index
        ]
      : specialtyPropertyInfo.report_json.company_info.company_industry;
  return (
    <div className={styles.companyInfoContainer}>
      <div className={styles.companyInfoComponentContainer}>
        <i className="pi pi-briefcase" />
        <span>{industry}</span>
      </div>
      <div className={styles.companyInfoComponentContainer}>
        <i className="pi pi-building" />
        <span>{`NAICS ${specialtyPropertyInfo.report_json.naics_info.code}`}</span>
      </div>
      <div className={styles.companyInfoComponentContainer}>
        <i className="pi pi-user" />
        <span>
          {brokerageFromBroker(
            specialtyPropertyInfo.report_json.company_info.broker_name
          )}
        </span>
      </div>
      <div className={styles.companyInfoComponentContainer}>
        <i className="pi pi-calendar" />
        <span>
          {specialtyPropertyInfo.report_json.company_info.effective_date}
        </span>
      </div>
    </div>
  );
};
