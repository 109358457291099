export const CONSTRUCTION_CODE_DICT: {
  [key: string]: { [key: string]: string };
} = {
  RMS: {
    "0": "Unknown",
    "1": "Wood",
    "2": "Masonry",
    "3": "Reinforced Concrete",
    "4": "Steel",
    "5": "Manufactured & Mobile Home",
    "6": "Conventional Bridges (<500 ft spans) with Multiple Simple Spans",
    "7": "Conventional Bridges (<500 ft spans) with Continuous/Monolithic Spans",
    "8": "Major Bridges (>500 ft spans)",
    "9": "Underground Pipelines",
    "10": "Pipelines at Grade",
    "11": "Concrete Dams",
    "12": "Earthfill and Rockfill Dams",
    "13": "Alluvium Tunnels",
    "14": "Rock Tunnels",
    "15": "Cut & Cover Tunnels",
    "16": "Underground Liquid Storage Tanks",
    "17": "Underground Solid Storage Tanks",
    "18": "On Ground Liquid Storage Tanks",
    "19": "On Ground Solid Storage Tanks",
    "20": "Elevated Liquid Storage Tanks",
    "21": "Elevated Solid Storage Tanks",
    "22": "Railroad (Track and Bed)",
    "23": "Highways",
    "24": "Runways",
    "25": "High Industrial Masonry Chimneys",
    "26": "High Industrial Concrete Chimneys",
    "27": "High Industrial Steel Chimneys",
    "28": "Cranes",
    "29": "Conveyor Systems",
    "30": "Conventional (<100 ft) Electrical Transmission Line Towers",
    "31": "Major (>100 ft) Electrical Transmission Line Towers",
    "32": "Broadcast Towers",
    "33": "Observation Towers",
    "34": "Offshore Towers",
    "35": "Canals",
    "36": "Earth Retaining Structures (>20 ft High)",
    "37": "Waterfront Structures",
    "38": "Residential Equipment",
    "39": "Office Equipment",
    "40": "Electrical Equipment",
    "41": "Mechanical Equipment",
    "42": "High Technology Lab Equipment",
    "43": "Trains, Trucks, Airplanes",
    "44": "Automobiles–Personal",
    "45": "Automobiles–Dealers",
    "53": "Golf Country Club",
    "54": "Boats Inside a Building (Marina)",
    "55": "Boats Moored, Less than 26 ft., Motor",
    "56": "Boats Moored, Less than 26 ft., Sail",
    "57": "Boats Moored, 26-60 ft., Motor",
    "58": "Boats Moored, 26-60 ft., Sail",
    "59": "Boats Moored, Greater than 60 ft., Motor",
    "60": "Boats Moored, Greater than 60 ft., Sail",
    "61": "Floating Structures",
    "62": "Structure with Response Control System",
    "63": "Aircraft Small",
    "64": "Aircraft Medium",
    "65": "Aircraft Large",
    "1A": "Light Wood Frame",
    "1A1": "Light Wood Stud Walls",
    "1A2": "Light Wood Post & Beam",
    "1A3": "Light Wood Stud Walls with Brick Masonry Veneer",
    "1A4": "Light Wood with URM/Adobe Infill",
    "1B": "Heavy Timber",
    "1B1": "Heavy Timber Frame",
    "1B2": "Heavy Timber Frame with Unreinforced Masonry Infill",
    "2A": "Weak Masonry",
    "2A1": "Rubble Stone Masonry",
    "2A2": "Adobe",
    "2A3": "Reinforced Adobe",
    "2B": "Unreinforced Masonry",
    "2B1": "Unreinforced Cut Stone Masonry",
    "2B2": "Unreinforced Solid Brick Masonry",
    "2B3": "Unreinforced Concrete Block Masonry",
    "2B4": "Unreinforced Brick Cavity Wall",
    "2B6": "Bahareque",
    "2B7": "Unreinforced Solid Brick Masonry – With Rigid Diaphragms",
    "2C": "Structural Masonry",
    "2C1": "Reinforced Masonry",
    "2C2": "Confined Masonry",
    "2C3": "Confined Masonry with non-RC Slab",
    "2C5": "Reinforced Masonry with Wood or Metal Studs in the Upper Floors",
    "2D": "Factory Structure with Brick Columns",
    "2E": "Multi-story Brick Structure with RC Frames",
    "3A": "Cast-in-place Reinforced Concrete",
    "3A1": "Reinforced Concrete Moment Resisting Frame (RCMRF)",
    "3A2": "RCMRF with Shear Walls",
    "3A3": "RCMRF with Unreinforced Masonry Infill",
    "3A4": "Reinforced Concrete Shear Wall",
    "3A5": "Waffle or Flat Slab",
    "3A6": "Steel & Reinforced Concrete (SRC) Composite Frame",
    "3A7": "Concrete Piloti",
    "3A8": "Concrete Filled Tube (CFT)",
    "3A9": "Gravity Load Designed RC with Unreinforced Masonry Infill",
    "3A10": "Reinforced Concrete Frame with Wood Frame in the Upper Floors",
    "3A11": "Reinforced Concrete Moment Resisting Frame (Perimeter)",
    "3A12": "Reinforced Concrete Moment Resisting Frame (Distributed)",
    "3A15":
      "Reinforced Concrete Moment Resisting Frame with hollow-core floors",
    "3A16":
      "Reinforced Concrete Moment Resisting Frame without hollow-core floors",
    "3A17": "Reinforced Concrete Shear Wall with hollow-core floors",
    "3A18": "Reinforced Concrete Shear Wall without hollow-core floors",
    "3A19":
      "Reinforced Concrete Moment Resisting Frame (Perimeter) with hollow-core floors",
    "3A20":
      "Reinforced Concrete Moment Resisting Frame(Perimeter) without hollow-core floors",
    "3A21": "Reinforced Concrete MRF (Distributed) with hollow-core floors",
    "3A22":
      "Reinforced Concrete Moment Resisting Frame (Distributed) without hollow-core floors",
    "3A23": "Reinforced Concrete Shear Walls with Limited Ductility",
    "3B": "Precast Reinforced Concrete",
    "3B1": "Precast Moment Resisting Frame",
    "3B2": "Precast MRF with Shear Walls",
    "3B3": "Precast MRF with Unreinforced Masonry Infill",
    "3B4": "Tilt-up",
    "3B5": "Precast Panel Bearing Wall",
    "3B6": "Lift Slab",
    "3C": "Reinforced Concrete",
    "3D": "Factory Structure with RC Columns",
    "4A": "Steel Frame",
    "4A1": "Steel Moment Resisting Frame (MRF)",
    "4A2": "Steel MRF with Shear Walls",
    "4A3": "Steel MRF with Unreinforced Masonry Infill",
    "4A4": "Concentrically Braced Steel Frame",
    "4A5": "Eccentrically Braced Steel Frame",
    "4A6": "Gravity Load Designed Steel with Unreinforced Masonry Infill",
    "4A7": "Steel Frame with Wood Frame in the Upper Floors",
    "4B": "Light Metal",
    "4B1": "Light Metal Frame",
    "4B2": "Light Metal Frame with Unreinforced Masonry Infill",
    "4B3": "Light Metal Stud Wall with Brick Masonry Veneer",
    "5A": "Manufactured/Mobile Home without Tie-Downs",
    "5B": "Manufactured/Mobile Home with Tie-Downs",
    "46*": "Petrochemical Facilities",
    "46A*": "Oil Refineries & Petrochemical Plants",
    "46B*": "Tank Farms",
    "46C*": "Pumping Stations",
    "46D*": "Pipelines",
    "47*": "Electric Power Systems",
    "47A*": "Transmission/Distribution Substations",
    "47B*": "Transmission/Distribution Lines",
    "47C*": "Fossil Fuel Plants",
    "47D*": "Cogeneration Plants",
    "47E*": "Hydroelectric Plants",
    "48*": "Natural Gas Facilities",
    "48A*": "Compressor Stations",
    "48B*": "Pipelines",
    "48C*": "Liquefied Natural Gas",
    "49*": "Port/Harbor Facilities",
    "49A*": "Waterfront Facilities",
    "49B*": "Crane/Cargo Handling",
    "49C*": "Fuel Facilities",
    "50*": "Heavy Industrial Facilities",
    "50A*": "Pulp & Paper",
    "50B*": "Steel Mill",
    "50C*": "Mining",
    "50D*": "Cement Mill",
    "51*": "Light Industrial Facilities",
    "51A*": "High Technology",
    "51B*": "Telecommunication",
    "62A": "Response Control System – Concrete",
    "62B": "Response Control System – Steel",
  },
  ATC: {
    "0": "Unknown",
    "1": "Wood Frame",
    "2": "Light Metal",
    "3": "Unreinforced Masonry Wall",
    "4": "URM Wall with Frame",
    "5": "RC Shear Wall with Frame",
    "6": "RC Shear Wall without Frame",
    "7": "RM Shear Wall",
    "8": "RM Shear Wall with Frame",
    "9": "Braced Steel Frame",
    "10": "Moment Steel Frame (Perimeter)",
    "11": "Moment Steel Frame (Distributed)",
    "12": "Ductile RC Frame (Distributed)",
    "13": "Non-Ductile RC Frame (Distributed)",
    "14": "Precast Concrete (Non Tilt-Up)",
    "15": "Long Span",
    "16": "Tilt-Up",
    "17": "Mobile Home",
    "18": "Specially Engineered Facility",
    "19": "Conventional Bridges (< 500 ft. spans) w/ Multiple Simple Spans",
    "20": "Conventional Bridges (< 500 ft. spans) w/ Monolithic Spans",
    "21": "Major Bridges (> 500 ft. spans)",
    "22": "Pipelines - Underground",
    "23": "Pipelines - At Grade",
    "24": "Concrete Dams",
    "25": "Earthfill and Rockfill Dams",
    "26": "Alluvium Tunnels",
    "27": "Rock Tunnels",
    "28": "Cut & Cover Tunnels",
    "29": "Storage Tank - Liquid - Underground",
    "30": "Storage Tank - Solids - Underground",
    "31": "Storage Tank - Liquid - On Ground",
    "32": "Storage Tank - Solids - On Ground",
    "33": "Storage Tank - Liquid - Elevated",
    "34": "Storage Tank - Solids - Elevated",
    "35": "Railroads (Track & Bed)",
    "36": "Highways",
    "37": "Runways",
    "38": "High Industrial Masonry Chimneys",
    "39": "High Industrial Concrete Chimneys",
    "40": "High Industrial Steel Chimneys",
    "41": "Cranes",
    "42": "Conveyor Systems",
    "43": "Conventional (< 100 ft.) Electrical Transmission Line Towers",
    "44": "Major (> 100 ft.) Electrical Transmission Line Towers",
    "45": "Broadcast Towers",
    "46": "Observation Towers",
    "47": "Offshore Towers",
    "48": "Canals",
    "49": "Earth Retaining Structures (> 20 ft. high)",
    "50": "Waterfront Structures",
    "51": "Residential Equipment",
    "52": "Office Equipment",
    "53": "Electrical Equipment",
    "54": "Mechanical Equipment",
    "55": "High Technology/Lab Equipment",
    "56": "Trains, Trucks, Airplanes",
    "80": "Reinforced Concrete Frame with Wood Frame in the Upper Floors",
    "81": "Steel Frame with Wood Frame in the Upper Floors",
    "82": "High Speed Railway",
    "57A*": "Oil Refineries & Petrochemical Plants",
    "57B*": "Tank Farms",
    "57C*": "Pumping Stations",
    "57D*": "Pipelines",
    "58A*": "Transmission/Distribution Substations",
    "58B*": "Transmission/Distribution Lines",
    "58C*": "Fossil Fuel Plants",
    "58D*": "Cogeneration Plants",
    "58E*": "Hydroelectric Plants",
    "59A*": "Compressor Stations",
    "59B*": "Pipelines",
    "59C*": "Liquefied Natural Gas",
    "60A*": "Waterfront Facilities",
    "60B*": "Crane/Cargo Handling",
    "60C*": "Fuel Facilities",
    "61A*": "Pulp & Paper",
    "61B*": "Steel Mill",
    "61C*": "Mining",
    "61D*": "Cement Mill",
    "62A*": "High Technology",
    "62B*": "Telecommunication",
    "72A": "Wood Frame OSHPD SPC1",
    "72B": "Wood Frame OSHPD SPC2",
    "72C": "Wood Frame OSHPD SPC3",
    "72D": "Wood Frame OSHPD SPC4",
    "72E": "Wood Frame OSHPD SPC5",
    "73A": "Unreinforced Masonry OSHPD Pre-1973 SPC1",
    "73B": "Unreinforced Masonry OSHPD Pre-1973 SPC2",
    "74A": "Structural Masonry OSHPD Pre-1973 SPC1",
    "74B": "Structural Masonry OSHPD Pre-1973 SPC2",
    "74C": "Structural Masonry OSHPD Pre-1973 SPC3",
    "74D": "Structural Masonry OSHPD 1974-1994 SPC2",
    "74E": "Structural Masonry OSHPD 1974-1994 SPC3",
    "74F": "Structural Masonry OSHPD Post-1995 SPC3",
    "75A": "Reinforced Concrete Moment Resisting Frame OSHPD Pre-1973 SPC1",
    "75B": "Reinforced Concrete Moment Resisting Frame OSHPD Pre-1973 SPC2",
    "75C": "Reinforced Concrete Moment Resisting Frame OSHPD 1974-1994 SPC2",
    "75D": "Reinforced Concrete Moment Resisting Frame OSHPD 1974-1994 SPC3",
    "75E": "Reinforced Concrete Moment Resisting Frame OSHPD 1974-1994 SPC4",
    "75F": "Reinforced Concrete Moment Resisting Frame OSHPD Post-1995 SPC3",
    "75G": "Reinforced Concrete Moment Resisting Frame OSHPD Post-1995 SPC4",
    "75H": "Reinforced Concrete Moment Resisting Frame OSHPD Post-1995 SPC5",
    "76A":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD Pre-1973 SPC1",
    "76B":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD Pre-1973 SPC2",
    "76C":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD 1974-1994 SPC2",
    "76D":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD 1974-1994 SPC3",
    "76E":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD 1974-1994 SPC4",
    "76F":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD Post-1995 SPC3",
    "76G":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD Post-1995 SPC4",
    "76H":
      "Reinforced Concrete Moment Resisting Frame with Shear Walls OSHPD Post-1995 SPC5",
    "77A": "Reinforced Concrete Shear Walls OSHPD Pre-1973 SPC1",
    "77B": "Reinforced Concrete Shear Walls OSHPD Pre-1973 SPC2",
    "77C": "Reinforced Concrete Shear Walls OSHPD 1974-1994 SPC2",
    "77D": "Reinforced Concrete Shear Walls OSHPD 1974-1994 SPC3",
    "77E": "Reinforced Concrete Shear Walls OSHPD 1974-1994 SPC4",
    "77F": "Reinforced Concrete Shear Walls OSHPD Post-1995 SPC3",
    "77G": "Reinforced Concrete Shear Walls OSHPD Post-1995 SPC4",
    "77H": "Reinforced Concrete Shear Walls OSHPD Post-1995 SPC5",
    "78A": "Steel Frame OSHPD Pre-1973 SPC1",
    "78B": "Steel Frame OSHPD Pre-1973 SPC2",
    "78C": "Steel Frame OSHPD 1974-1994 SPC2",
    "78D": "Steel Frame OSHPD 1974-1994 SPC3",
    "78E": "Steel Frame OSHPD 1974-1994 SPC4",
    "78F": "Steel Frame OSHPD Post-1995 SPC3",
    "78G": "Steel Frame OSHPD Post-1995 SPC4",
    "78H": "Steel Frame OSHPD Post-1995 SPC5",
    "79A": "Post-Tensioned Concrete OSHPD Pre-1973 SPC1",
    "79B": "Post-Tensioned Concrete OSHPD Pre-1973 SPC2",
    "79C": "Post-Tensioned Concrete OSHPD 1974-1994 SPC2",
    "79D": "Post-Tensioned Concrete OSHPD 1974-1994 SPC3",
    "79E": "Post-Tensioned Concrete OSHPD 1974-1994 SPC4",
    "79F": "Post-Tensioned Concrete OSHPD Post-1995 SPC3",
    "79G": "Post-Tensioned Concrete OSHPD Post-1995 SPC4",
    "79H": "Post-Tensioned Concrete OSHPD Post-1995 SPC5",
  },
};
