export const OCCUPANCY_CODE_DICT: { [key: string]: { [key: string]: string } } =
  {
    ATC: {
      "0": "Unknown",
      "1": "Permanent Dwelling (single family housing)",
      "2": "Permanent Dwelling (multi family housing)",
      "3": "Temporary Lodging",
      "4": "Group Institutional Housing",
      "5": "Retail Trade",
      "6": "Wholesale Trade",
      "7": "Personal and Repair Services",
      "8": "Professional, Technical and Business Services",
      "9": "Health Care Service",
      "10": "Entertainment and Recreation",
      "11": "Parking",
      "12": "Heavy Fabrication and Assembly",
      "13": "Light Fabrication and Assembly",
      "14": "Food and Drugs Processing",
      "15": "Chemicals Processing",
      "16": "Metal and Minerals Processing",
      "17": "High Technology",
      "18": "Construction",
      "19": "Petroleum",
      "20": "Agriculture",
      "21": "Mining",
      "22": "Religion and Nonprofit",
      "23": "General Services",
      "24": "Emergency Response Services",
      "25": "Education",
      "26": "Highway",
      "27": "Railroad",
      "28": "Air",
      "29": "Sea/Water",
      "30": "Electrical",
      "31": "Water",
      "32": "Sanitary Sewer",
      "33": "Natural Gas",
      "34": "Telephone & Telegraph",
      "35": "Communication (Radio and TV)",
      "36": "Flood Control",
      "37": "General Commercial",
      "38": "General Industrial",
      "39": "Miscellaneous",
      "42": "Multi-Family Dwelling–Homeowners Association",
      "43": "Multi-Family Dwelling–Condominium Unit Owner",
      "44": "Gasoline Service Station",
      "47": "Restaurants",
      "48": "Casinos",
      "49": "Acute Care Services Hospitals",
      "51": "Hotels - Large",
      "52": "Hotels - Small and Medium",
      "53": "Rental - General Commercial",
      "54": "Universities and Colleges",
    },
  };
