import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  UpdateReport,
  listReport,
  listReports,
  listUsers,
  searchAddresses,
  updateReport,
} from "../../api/routes";
import * as client from "../../client";
import {
  applyOverridesToAddress,
  applyOverridesToResponse,
} from "./Conversion";
import { mapValues } from "lodash";

export const useReports = () => {
  return useQuery({
    queryKey: ["reports"],
    queryFn: async () => {
      return await listReports();
    },
  });
};

export const useReport = (reportId?: string) => {
  return useQuery({
    queryKey: ["reports", reportId],
    queryFn: async () => {
      return reportId ? await listReport(reportId) : undefined;
    },
  });
};

export const useSafeReport = (reportId: string) => {
  return useQuery({
    queryKey: ["reports", reportId],
    queryFn: async () => {
      return applyOverridesToResponse(await listReport(reportId));
    },
  });
};

export const useUpdateReport = (reportId: string) => {
  const key = ["reports", reportId];
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: key,
    mutationFn: async (update: UpdateReport) =>
      await updateReport(reportId, update),
    onSuccess: (data, update) => {
      // invalidate prefix matches against queryKeys
      // currently, only assignee and status affect the list view
      if (update.assignee || update.status) {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
      }
      queryClient.setQueryData(["reports", reportId], data);
    },
  });
};

export const useUsers = (clientId?: string | null) => {
  if (clientId === "proper") {
    return undefined;
  }
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return await listUsers();
    },
  });
};

export const useUserById = (
  userId: string | undefined | null
): client.User | undefined => {
  const users = useUsers();
  if (!userId) return undefined;
  return (users?.data?.all_company_users ?? []).find((u) => u.id === userId);
};

export const useAddressSearch = (query: string, terms: client.TermSearch[]) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["search-addresses", query, ...terms],
    queryFn: async () => {
      const response =
        query || terms.length > 0
          ? await searchAddresses(query, terms)
          : { by_report: {}, term_query: [] };

      return {
        by_report: mapValues(response.by_report, (addresses) =>
          addresses.map(applyOverridesToAddress)
        ),
      };
    },
  });
};
