import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import * as client from "../../../client";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import styles from "./EditSovModal.module.css";
import { useUpdateReport } from "../data";
import { isEqual, clone, cloneDeep } from "lodash";
import { applyOverridesToAddress } from "../Conversion";
import { CONSTRUCTION_CODE_DICT } from "./construction";
import { OCCUPANCY_CODE_DICT } from "./occupancy";

type EditSovModalProps = {
  sovs: client.SovInput[];
  reportId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  initialActiveIndex: number | undefined;
};

export const EditSovModal: React.FC<EditSovModalProps> = ({
  sovs,
  reportId,
  visible,
  setVisible,
  initialActiveIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex ?? 0);
  useEffect(() => {
    setActiveIndex(initialActiveIndex ?? 0);
  }, [initialActiveIndex]);
  const [newSovs, setNewSovs] = useState<client.SovInput[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  // If the input sovs change, set is saving to false. This likely means
  // our edits have finished.
  useEffect(() => {
    if (visible) {
      setIsSaving(false);
      setNewSovs(cloneDeep(sovs));
    }
  }, [sovs, visible]);

  const updateReport = useUpdateReport(reportId);

  const saveEdits = async () => {
    // Is saving will be set to false automatically once this succeeds
    setIsSaving(true);
    updateReport.mutate({ sovs: newSovs });
  };

  const sovsAreEqual = useMemo(() => isEqual(sovs, newSovs), [sovs, newSovs]);

  return (
    <Dialog
      header={
        <div className={styles.buttonContainer}>
          <span style={{ marginRight: "16px" }}>Edit SOV</span>
          <Button
            label="Save Edits"
            disabled={sovsAreEqual}
            onClick={saveEdits}
            loading={isSaving}
          />
          <Button
            severity="secondary"
            label="Clear Edits"
            disabled={sovsAreEqual}
            onClick={() => setNewSovs(cloneDeep(sovs))}
          />
          <EditSheets
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </div>
      }
      visible={visible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={() => setVisible(false)}
    >
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Occupancy">
          <EditOccupancy
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </TabPanel>
        <TabPanel header="Construction">
          <EditConstruction
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

type EditProps = {
  sovs: client.SovInput[];
  setNewSovs: Dispatch<SetStateAction<client.SovInput[]>>;
  isSaving: boolean;
};

const EditSheets: React.FC<EditProps> = ({ sovs, setNewSovs, isSaving }) => {
  const filenames = sovs.map((sov) => {
    return { name: sov.filename };
  });

  const selectedFilenames = sovs
    .filter((sov) => sov.is_enabled)
    .map((sov) => {
      return { name: sov.filename };
    });

  return (
    <MultiSelect
      disabled={isSaving}
      value={selectedFilenames}
      onChange={(e) => {
        setNewSovs((sovs: client.SovInput[]) => {
          const enabledFilenames = e.value as { name: string }[];
          const newSovs = sovs.map((sov) => ({
            ...sov,
            is_enabled: enabledFilenames.some(
              ({ name }) => name === sov.filename
            ),
          }));

          return newSovs;
        });
      }}
      options={filenames}
      optionLabel="name"
      placeholder="Select Files/Sheets"
      style={{ width: "260px" }}
      selectAllLabel="All"
    />
  );
};

type GroupedAddresses = {
  [key: string]: {
    [key: string]: {
      [key: string]: client.RawAddress[];
    };
  };
};
type CodeCount = {
  code: string;
  codeType: string;
  description: string;
  originalDescription: string;
  count: number;
};
const NONE_GIVEN = "NONE GIVEN";
const NONE_FOUND = "NONE FOUND";
const CODE_SPLITTER = "___";

const textEditor = (options: ColumnEditorOptions, isSaving: boolean) => {
  return (
    <InputText
      disabled={isSaving}
      type="text"
      value={options.value}
      onChange={(e) => {
        options.editorCallback && options.editorCallback(e.target.value);
      }}
    />
  );
};

const originalOccupancyDescriptionFromRawAddress = (
  rawAddress: client.Address
) => {
  return (
    rawAddress.private_original_values["occupancy_description"] ??
    rawAddress.private_original_values["location_description"] ??
    rawAddress.private_original_values["location_name"] ??
    NONE_GIVEN
  );
};

const occupancyDescriptionFromCode = (
  codeType: string | undefined,
  code: string | undefined
) => {
  return OCCUPANCY_CODE_DICT[codeType ?? ""]?.[code ?? ""] ?? NONE_FOUND;
};

const EditOccupancy: React.FC<EditProps> = ({ sovs, setNewSovs, isSaving }) => {
  const groupedOccupancies: GroupedAddresses = {};
  for (const sov of sovs) {
    if (sov.is_enabled) {
      for (const rawAddress of sov.addresses) {
        const address = applyOverridesToAddress(rawAddress);
        if (address.occupancy_code && address.occupancy_code_type) {
          const occCode =
            address.occupancy_code +
            CODE_SPLITTER +
            address.occupancy_code_type;
          if (!(occCode in groupedOccupancies)) {
            groupedOccupancies[occCode] = {};
          }
          const originalDescription =
            originalOccupancyDescriptionFromRawAddress(rawAddress);
          const occDescription = occupancyDescriptionFromCode(
            address.occupancy_code_type,
            address.occupancy_code
          );
          if (!(occDescription in groupedOccupancies[occCode])) {
            groupedOccupancies[occCode][occDescription] = {};
          }
          if (
            !(
              originalDescription in groupedOccupancies[occCode][occDescription]
            )
          ) {
            groupedOccupancies[occCode][occDescription][originalDescription] =
              [];
          }
          groupedOccupancies[occCode][occDescription][originalDescription].push(
            address
          );
        }
      }
    }
  }

  const occupancyCounts: CodeCount[] = [];
  for (const code of Object.keys(groupedOccupancies)) {
    for (const description of Object.keys(groupedOccupancies[code])) {
      for (const originalDescription of Object.keys(
        groupedOccupancies[code][description]
      )) {
        const addresses =
          groupedOccupancies[code][description][originalDescription];
        const codeParts = code.split(CODE_SPLITTER);
        const occupancyCount: CodeCount = {
          code: codeParts[0],
          codeType: codeParts[1],
          description: description,
          originalDescription: originalDescription,
          count: addresses.length,
        };
        occupancyCounts.push(occupancyCount);
      }
    }
  }
  occupancyCounts.sort((a, b) => b.count - a.count);

  return (
    <DataTable
      value={occupancyCounts}
      editMode="row"
      onRowEditComplete={(e) => {
        // If there were no edits, exit early
        if (JSON.stringify(e.data) === JSON.stringify(e.newData)) {
          return;
        }
        // Prevent saving the empty string, which can have a bad effect
        if (e.newData.code === "" || e.newData.codeType === "") {
          return;
        }

        setNewSovs((sovs) => {
          const newSovs = [];
          for (const sov of sovs) {
            const newSov = clone(sov);
            for (const rawAddress of newSov.addresses) {
              const address = applyOverridesToAddress(rawAddress);
              if (
                address.occupancy_code_type === e.data.codeType &&
                address.occupancy_code === e.data.code &&
                originalOccupancyDescriptionFromRawAddress(rawAddress) ===
                  e.data.originalDescription
              ) {
                rawAddress.private_overrides.occupancy_code = e.newData.code;
                rawAddress.private_overrides.occupancy_code_type =
                  e.newData.codeType;
              }
            }
            newSovs.push(newSov);
          }
          return newSovs;
        });
      }}
    >
      <Column field="originalDescription" header="Input Description" />
      <Column
        field="code"
        header="Occupancy Code"
        editor={(options) => textEditor(options, isSaving)}
      />
      <Column
        field="codeType"
        header="Code Type"
        editor={(options) => textEditor(options, isSaving)}
      />
      <Column field="description" header="Occupancy Code Description" />
      <Column field="count" header="Count" />
      <Column
        rowEditor={true}
        headerStyle={{ width: "10%", minWidth: "8rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};

const originalConstructionDescriptionFromRawAddress = (
  rawAddress: client.Address
) => {
  return (
    rawAddress.private_original_values["construction_description"] ?? NONE_GIVEN
  );
};

const constructionDescriptionFromCode = (
  codeType: string | undefined,
  code: string | undefined
) => {
  return CONSTRUCTION_CODE_DICT[codeType ?? ""]?.[code ?? ""] ?? NONE_FOUND;
};

const EditConstruction: React.FC<EditProps> = ({
  sovs,
  setNewSovs,
  isSaving,
}) => {
  const groupedConstructions: GroupedAddresses = {};
  for (const sov of sovs) {
    if (sov.is_enabled) {
      for (const rawAddress of sov.addresses) {
        const address = applyOverridesToAddress(rawAddress);
        if (address.construction_code && address.construction_code_type) {
          const conCode =
            address.construction_code +
            CODE_SPLITTER +
            address.construction_code_type;
          if (!(conCode in groupedConstructions)) {
            groupedConstructions[conCode] = {};
          }

          const conDescription = constructionDescriptionFromCode(
            address.construction_code_type,
            address.construction_code
          );
          const originalDescription =
            originalConstructionDescriptionFromRawAddress(rawAddress);
          if (!(conDescription in groupedConstructions[conCode])) {
            groupedConstructions[conCode][conDescription] = {};
          }
          if (
            !(
              originalDescription in
              groupedConstructions[conCode][conDescription]
            )
          ) {
            groupedConstructions[conCode][conDescription][originalDescription] =
              [];
          }

          groupedConstructions[conCode][conDescription][
            originalDescription
          ].push(address);
        }
      }
    }
  }

  const constructionCounts: CodeCount[] = [];
  for (const code of Object.keys(groupedConstructions)) {
    for (const description of Object.keys(groupedConstructions[code])) {
      for (const originalDescription of Object.keys(
        groupedConstructions[code][description]
      )) {
        const addresses =
          groupedConstructions[code][description][originalDescription];
        const codeParts = code.split(CODE_SPLITTER);
        const constructionCount: CodeCount = {
          code: codeParts[0],
          codeType: codeParts[1],
          description: description,
          originalDescription: originalDescription,
          count: addresses.length,
        };
        constructionCounts.push(constructionCount);
      }
    }
  }
  constructionCounts.sort((a, b) => b.count - a.count);

  return (
    <DataTable
      value={constructionCounts}
      editMode="row"
      onRowEditComplete={(e) => {
        // If there were no edits, exit early
        if (JSON.stringify(e.data) === JSON.stringify(e.newData)) {
          return;
        }
        // Prevent saving the empty string, which can have a bad effect
        if (e.newData.code === "" || e.newData.codeType === "") {
          return;
        }

        setNewSovs((sovs) => {
          const newSovs = [];
          for (const sov of sovs) {
            const newSov = clone(sov);
            for (const rawAddress of newSov.addresses) {
              const address = applyOverridesToAddress(rawAddress);
              if (
                address.construction_code_type === e.data.codeType &&
                address.construction_code === e.data.code &&
                originalConstructionDescriptionFromRawAddress(rawAddress) ===
                  e.data.originalDescription
              ) {
                rawAddress.private_overrides.construction_code = e.newData.code;
                rawAddress.private_overrides.construction_code_type =
                  e.newData.codeType;
              }
            }
            newSovs.push(newSov);
          }
          return newSovs;
        });
      }}
    >
      <Column field="originalDescription" header="Input Description" />
      <Column
        field="code"
        header="Construction Code"
        editor={(options) => textEditor(options, isSaving)}
      />
      <Column
        field="codeType"
        header="Code Type"
        editor={(options) => textEditor(options, isSaving)}
      />
      <Column field="description" header="Construction Code Description" />
      <Column field="count" header="Count" />
      <Column
        rowEditor={true}
        headerStyle={{ width: "10%", minWidth: "8rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};
