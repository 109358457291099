import { Card } from "primereact/card";
import styles from "./LayersCard.module.css";
import { formatAbbreviatedMoney } from "../Money";
import { InsuranceLayer, sortedLayersFromReport } from "./util";
import { isDefined } from "../../../util";
import { useMemo } from "react";
import classNames from "classnames";
import { SafeReportResponse } from "../Conversion";
import * as React from "react";

type LayersCardProps = {
  specialtyPropertyInfo: SafeReportResponse;
  title?: string;
};

interface LayerLoss {
  loss: number;
  description: string;
  colorClass: string;
}

export const LayersCard: React.FC<LayersCardProps> = ({
  specialtyPropertyInfo,
  title,
}) => {
  const sortedLayers = sortedLayersFromReport(specialtyPropertyInfo);
  const layerRanges = Array.from(
    new Set(sortedLayers.map((layer) => layer.range))
  ).sort((a, b) => a - b);

  let losses: LayerLoss[] = (
    specialtyPropertyInfo.report_json.loss_run.loss_runs_by_claim ?? []
  )
    .map((claimLoss) => {
      if (
        !claimLoss.net_incurred?.value ||
        claimLoss.net_incurred.value === 0
      ) {
        return undefined;
      }
      return {
        loss: claimLoss.net_incurred.value,
        description: `${claimLoss.description} (${claimLoss.policy_year})`,
        colorClass: styles.lossRed,
      };
    })
    .filter((loss) => isDefined(loss)) as LayerLoss[];

  losses.sort((a, b) => b.loss - a.loss);
  losses = losses.slice(0, 3);

  const addresses = useMemo(
    () =>
      specialtyPropertyInfo.report_json.sovs
        .filter((sov) => sov.is_enabled)
        .flatMap((sov) => sov.addresses),
    [specialtyPropertyInfo]
  );
  const totalTIV = useMemo(
    () => addresses.reduce((total, address) => total + address.tiv, 0),
    [addresses]
  );

  const potentialCatLosses: LayerLoss[] = [
    {
      loss: 0.00252941176 * totalTIV,
      description: "1/100 Named Wind",
      colorClass: styles.lossYellow,
    },
    {
      loss: 0.0009117647 * totalTIV,
      description: "1/100 CA Earthquake",
      colorClass: styles.lossOrange,
    },
  ];

  const allLosses = [...potentialCatLosses, ...losses];

  return (
    <Card title={title || "Layers"}>
      <div className={styles.container}>
        <div className={styles.barGraphContainer}>
          {sortedLayers.map((layer, index) => (
            <BarGraphComponent
              maxIndex={sortedLayers.length - 1}
              index={index}
              layer={layer}
              key={index}
              layerRanges={layerRanges}
              losses={allLosses}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

const textColorForIndex = (index: number, maxIndex: number) => {
  if (maxIndex > 8) {
    index -= 1;
  }
  if (index <= 4) {
    return "var(--bluegray-900)";
  }
  return "var(--bluegray-50)";
};

const backgroundColorForIndex = (index: number, maxIndex: number) => {
  if (maxIndex <= 8) {
    return `var(--cyan-${index + 1}00)`;
  }

  if (index == 0) {
    return `var(--cyan-50)`;
  }
  return `var(--cyan-${index}00)`;
};

type BarGraphComponentProps = {
  index: number;
  maxIndex: number;
  layer: InsuranceLayer;
  layerRanges: number[];
  losses: LayerLoss[];
};

const BarGraphComponent: React.FC<BarGraphComponentProps> = ({
  index,
  maxIndex,
  layer,
  layerRanges,
  losses,
}) => {
  const { startingPoint, range, target } = layer;
  const indexOfRange = layerRanges.findIndex((r) => r === range);

  // The smallest layer will always be 60px, then we will add 12 for each level bigger
  const layerHeightInPixels = 80 + indexOfRange * 20;
  const readableTitle =
    startingPoint === 0
      ? `Primary ${formatAbbreviatedMoney(range)}`
      : `${formatAbbreviatedMoney(range)} xs ${formatAbbreviatedMoney(
          startingPoint
        )}`;
  const svgLineHeight = (layerHeightInPixels - 22) / 2;
  const lossWidth = 60;
  const lossGap = 4;

  return (
    <div className={styles.barRowContainer}>
      <div
        className={styles.rangeContainer}
        style={{
          height: `${layerHeightInPixels - 6}px`,
        }}
      >
        <TLineTop height={svgLineHeight} />
        <span className={styles.rangeText}>
          {formatAbbreviatedMoney(range)}
        </span>
        <TLineBottom height={svgLineHeight} />
      </div>
      <div
        className={styles.barGraphComponent}
        style={{
          height: `${layerHeightInPixels}px`,
          backgroundColor: backgroundColorForIndex(index, maxIndex),
        }}
      >
        <span style={{ color: textColorForIndex(index, maxIndex) }}>
          {readableTitle}
        </span>
      </div>
      <div
        className={styles.ratingContainer}
        style={{
          height: `${layerHeightInPixels}px`,
        }}
      >
        <span>
          {target === null
            ? "No target given"
            : `Target: ${formatAbbreviatedMoney(target)}`}
        </span>
      </div>
      <div
        className={styles.barGraphComponent}
        style={{
          height: `${layerHeightInPixels}px`,
          backgroundColor: backgroundColorForIndex(index, maxIndex),
          position: "relative",
          width: `${
            lossWidth * losses.length + lossGap * (losses.length + 1)
          }px`,
        }}
      >
        {losses.map((loss, lossIndex) => {
          let lossHeight = layerHeightInPixels;
          if (loss.loss <= layer.startingPoint) {
            lossHeight = 0;
          } else if (loss.loss <= layer.startingPoint + layer.range) {
            lossHeight =
              ((loss.loss - layer.startingPoint) / layer.range) *
              layerHeightInPixels;
          }

          return (
            <React.Fragment key={lossIndex}>
              <div
                className={classNames(styles.lossDiv, loss.colorClass)}
                style={{
                  width: `${lossWidth}px`,
                  height: `${lossHeight}px`,
                  left: `${
                    lossIndex * lossWidth + (lossIndex + 1) * lossGap
                  }px`,
                }}
              />
              {index === maxIndex && (
                <span
                  key={lossIndex}
                  className={styles.lossText}
                  style={{
                    width: `${lossWidth}px`,

                    left: `${
                      lossIndex * lossWidth + (lossIndex + 1) * lossGap
                    }px`,
                  }}
                >
                  {loss.description}
                </span>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const TLineTop = ({ height }: { height: number }) => (
  <svg width="16" height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d={`M8 ${height} L8 0 M0 0 L16 0`}
      stroke="var(--surface-400)"
      strokeWidth="1"
    />
  </svg>
);

const TLineBottom = ({ height }: { height: number }) => (
  <svg width="16" height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d={`M8 ${height} L8 0 M0 ${height} L16 ${height}`}
      stroke="var(--text-color-secondary)"
      strokeWidth="1"
    />
  </svg>
);
