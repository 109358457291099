import { Card } from "primereact/card";
import * as client from "../../../client";
import styles from "./Analytics.module.css";
import { useContext, useState } from "react";
import { Chart } from "primereact/chart";
import { ThemeContext } from "../../../themes/Theme";
import { appetiteScoreForReportInfo } from "./util";
import { appetiteTitleForScore } from "../appetite/AppetiteIcon";
import { Dropdown } from "primereact/dropdown";
import { useUsers } from "../data";
import { MultiSelect } from "primereact/multiselect";
import { displayNameForUser } from "../../../util";
import { Avatar } from "primereact/avatar";
import { generateIdenticonDataUrl } from "../../../components/avatar/Avatar";

type DashboardAnalyticsProps = {
  specialtyReports: client.ReportMetadataResponse[];
};

export const DashboardAnalytics: React.FC<DashboardAnalyticsProps> = ({
  specialtyReports,
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  const gridLineColor = isLightMode
    ? "rgba(0, 0, 0, 0.1)"
    : "rgba(255, 255, 255, 0.2)";
  const textColor = isLightMode ? "#000000" : "#ffffff";

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <Card title="Gross Written Premium" className={styles.analyticsCard}>
          <WrittenPremiumChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
          />
        </Card>
        <OverviewChart
          gridLineColor={gridLineColor}
          textColor={textColor}
          specialtyReports={specialtyReports}
        />
        <Card
          title="Monthly Submissions By Brokerage"
          className={styles.analyticsCard}
        >
          <BrokerageChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
          />
        </Card>
        <Card title="Submissions By Appetite" className={styles.analyticsCard}>
          <AppetiteChart
            gridLineColor={gridLineColor}
            textColor={textColor}
            specialtyReports={specialtyReports}
          />
        </Card>
      </div>
    </div>
  );
};

const GREEN = {
  backgroundColor: "rgba(75, 192, 192, 0.2)",
  borderColor: "rgb(75, 192, 192)",
};
const BLUE = {
  backgroundColor: "rgba(54, 162, 235, 0.2)",
  borderColor: "rgb(54, 162, 235)",
};
const ORANGE = {
  backgroundColor: "rgba(255, 159, 64, 0.2)",
  borderColor: "rgb(255, 159, 64)",
};
const RED = {
  backgroundColor: "rgba(255, 99, 132, 0.2)",
  borderColor: "rgb(255, 99, 132)",
};
const YELLOW = {
  backgroundColor: "rgba(255, 206, 86, 0.2)",
  borderColor: "rgb(255, 206, 86)",
};

const colorPalette = [
  ORANGE,
  GREEN,
  BLUE,
  {
    backgroundColor: "rgba(153, 102, 255, 0.2)",
    borderColor: "rgb(153, 102, 255)",
  },
  RED,
  YELLOW,
  {
    backgroundColor: "rgba(201, 203, 207, 0.2)",
    borderColor: "rgb(201, 203, 207)",
  },
  {
    backgroundColor: "rgba(153, 255, 153, 0.2)",
    borderColor: "rgb(153, 255, 153)",
  },
  {
    backgroundColor: "rgba(102, 51, 153, 0.2)",
    borderColor: "rgb(102, 51, 153)",
  },
];

type ChartProps = {
  specialtyReports: client.ReportMetadataResponse[];
  gridLineColor: string;
  textColor: string;
};

const WrittenPremiumChart: React.FC<ChartProps> = ({
  gridLineColor,
  textColor,
}) => {
  const data = [150, 160, 20];

  const chartData = {
    labels: ["Target", "Projected", "YTD"],
    datasets: [
      {
        label: "Premium",
        data: data,
        backgroundColor: [
          colorPalette[0].backgroundColor,
          colorPalette[1].backgroundColor,
          colorPalette[2].backgroundColor,
        ],
        borderColor: [
          colorPalette[0].borderColor,
          colorPalette[1].borderColor,
          colorPalette[2].borderColor,
        ],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
          callback: (value: number) => `${value} MM`,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "270px" }}
    />
  );
};

const OverviewChart: React.FC<ChartProps> = ({
  gridLineColor,
  textColor,
  specialtyReports,
}) => {
  const users = (useUsers()?.data?.all_company_users ?? []).map((user) => {
    return { ...user, full_name: user.full_name ?? user.email };
  });
  const [selectedUsers, setSelectedUsers] = useState<client.User[]>([]);

  const years = [
    { name: "2025", code: "2025" },
    { name: "2024", code: "2024" },
    { name: "2023", code: "2023" },
  ];
  const [selectedYear, setSelectedYear] = useState({
    name: "2025",
    code: "2025",
  });

  const dataByYear: { [key: string]: number[] } = {
    2025: [
      specialtyReports.filter(
        (report) =>
          selectedUsers.length === 0 ||
          (selectedUsers.length > 0 &&
            selectedUsers.some((user) => user.id === report.assignee))
      ).length,
      specialtyReports.filter(
        (report) =>
          report.status === "uninterested" &&
          (selectedUsers.length === 0 ||
            (selectedUsers.length > 0 &&
              selectedUsers.some((user) => user.id === report.assignee)))
      ).length,
      specialtyReports.filter(
        (report) =>
          report.status === "bound" &&
          (selectedUsers.length === 0 ||
            (selectedUsers.length > 0 &&
              selectedUsers.some((user) => user.id === report.assignee)))
      ).length,
    ],
    2024: [2700, 1800, 800],
    2023: [2400, 1500, 500],
  };
  const data = dataByYear[selectedYear.code];

  const chartData = {
    labels: ["Total", "Quoted", "Bound"],
    datasets: [
      {
        label: "Submissions",
        data: data,
        backgroundColor: [
          colorPalette[0].backgroundColor,
          colorPalette[1].backgroundColor,
          colorPalette[2].backgroundColor,
          colorPalette[3].backgroundColor,
        ],
        borderColor: [
          colorPalette[0].borderColor,
          colorPalette[1].borderColor,
          colorPalette[2].borderColor,
          colorPalette[3].borderColor,
        ],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Card
      className={styles.analyticsCard}
      header={
        <div className={styles.cardHeader}>
          <div className="p-card-title">Submissions</div>
          <div className={styles.horizontalContainer}>
            <MultiSelect
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e.value)}
              placeholder="All Underwriters"
              optionLabel="full_name"
              options={users}
              style={{ maxWidth: "200px" }}
              itemTemplate={(user: client.User) => {
                const name = displayNameForUser(user);
                return (
                  <div className={styles.horizontalContainer}>
                    <Avatar
                      label={name.toUpperCase()}
                      shape="circle"
                      image={generateIdenticonDataUrl(name)}
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <span>{name}</span>
                  </div>
                );
              }}
            />
            <Dropdown
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.value)}
              options={years}
              optionLabel="name"
              placeholder="Select a Year"
            />
          </div>
        </div>
      }
    >
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        style={{ height: "260px" }}
      />
    </Card>
  );
};

const AppetiteChart: React.FC<ChartProps> = ({
  textColor,
  gridLineColor,
  specialtyReports,
}) => {
  const codeMapping: { [key: string]: number } = {};
  for (const report of specialtyReports) {
    const appetiteScore = String(
      appetiteScoreForReportInfo(
        report.report_metadata.business_classification,
        report.report_metadata.appetite_score
      )
    );

    if (!(appetiteScore in codeMapping)) {
      codeMapping[appetiteScore] = 1;
    } else {
      codeMapping[appetiteScore] = codeMapping[appetiteScore] + 1;
    }
  }

  const sortedScores = Object.keys(codeMapping).sort().reverse();
  const appetiteTitles = sortedScores.map((score) =>
    appetiteTitleForScore(Number(score))
  );
  const sortedValues = sortedScores.map((score) => codeMapping[score]);
  const appetiteColors = sortedScores.map((score) => {
    switch (score) {
      case "5":
        return GREEN;
      case "4":
        return BLUE;
      case "3":
        return YELLOW;
      case "2":
        return ORANGE;
      default:
        return RED;
    }
  });
  const chartData = {
    labels: appetiteTitles,
    datasets: [
      {
        label: "Submissions",
        data: sortedValues,
        backgroundColor: appetiteColors.map(
          (palette) => palette.backgroundColor
        ),
        borderColor: appetiteColors.map((palette) => palette.borderColor),
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ maxHeight: "260px" }}
    />
  );
};

const BrokerageChart: React.FC<ChartProps> = ({
  gridLineColor,
  textColor,
  specialtyReports,
}) => {
  // Helper function to get month name from a date string
  const getMonthName = (date: string) => {
    return new Date(date).toLocaleString("default", { month: "long" });
  };

  // Get the last three months
  const now = new Date();
  const lastThreeMonths = Array.from({ length: 3 }, (_, i) => {
    const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
    return month.toLocaleString("default", { month: "long" });
  }).reverse();

  // Organize the data by broker and month
  const brokerData: { [key: string]: { [key: string]: number } } = {};
  specialtyReports.forEach((report) => {
    const brokerName = report.report_metadata.company_info.broker_name;
    const reportMonth = getMonthName(report.created_at_ts);

    if (lastThreeMonths.includes(reportMonth)) {
      if (!brokerData[brokerName]) {
        brokerData[brokerName] = {};
      }
      if (!brokerData[brokerName][reportMonth]) {
        brokerData[brokerName][reportMonth] = 0;
      }
      brokerData[brokerName][reportMonth] += 1;
    }
  });

  // Generate chartData with labels for the last three months
  const chartData = {
    labels: lastThreeMonths,
    datasets: Object.keys(brokerData).map((broker, index) => {
      const color = colorPalette[index % colorPalette.length];
      return {
        type: "bar",
        label: broker,
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor,
        borderWidth: 1,
        data: lastThreeMonths.map((month) => brokerData[broker][month] || 0),
      };
    }),
  };

  const chartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "270px" }}
    />
  );
};
