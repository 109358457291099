import { useContext, useEffect, useMemo, useState } from "react";
import * as client from "../../../client";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatAbbreviatedMoney } from "../Money";
import { Card } from "primereact/card";
import { Fieldset } from "primereact/fieldset";
import styles from "./SummaryCard.module.css";
import { TerribleMoney } from "../loss-run/LossRunCard";
import { getNumYearsAgo } from "../loss-run/DeductibleCalculator";
import { reverse, sortBy } from "lodash";
import {
  hasOverlappingLayers,
  lossRunErrorsForReport,
  sortedLayersFromReport,
} from "../layers/util";
import { Message } from "primereact/message";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Divider } from "primereact/divider";
import { TabPanel, TabView } from "primereact/tabview";
import { useUpdateReport, useUserById, useUsers } from "../data";
import { CompanyContext } from "../../../CompanyConfig";
import { PantheonAvatar } from "../../../components/avatar/Avatar";
import { displayNameForUser } from "../../../util";
import { ROUTES } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../themes/Theme";
import { Checkbox } from "primereact/checkbox";
import { SafeReportResponse } from "../Conversion";

type SummaryCardProps = {
  specialtyPropertyInfo: SafeReportResponse;
  forPrint?: boolean;
};

export const SummaryCard: React.FC<SummaryCardProps> = ({
  specialtyPropertyInfo,
  forPrint,
}) => {
  const addresses = useMemo(
    () =>
      specialtyPropertyInfo.report_json.sovs
        .filter((sov) => sov.is_enabled)
        .flatMap((sov) => sov.addresses),
    [specialtyPropertyInfo]
  );
  const totalTIV = useMemo(
    () => addresses.reduce((total, address) => total + address.tiv, 0),
    [addresses]
  );

  return (
    <Card title="Summary">
      {forPrint ? (
        <div className={styles.printContainer}>
          <Fieldset legend="TIV/Loss History">
            <TivAndLossHistory
              specialtyPropertyInfo={specialtyPropertyInfo}
              totalTIV={totalTIV}
            />
          </Fieldset>
          <Fieldset
            legend={
              <div className={styles.warningVerticalContainer}>
                <span>TIV Summary (State by State)</span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "var(--text-color)",
                  }}
                >
                  The increase in TIV was driven by an increase in locations in
                  Florida.
                </span>
              </div>
            }
          >
            <StateTivTable specialtyPropertyInfo={specialtyPropertyInfo} />
          </Fieldset>
          <Fieldset legend="CAT Events">
            <CatEvents totalTIV={totalTIV} />
          </Fieldset>
          <Fieldset legend="Layers By Year">
            <LayersByYear specialtyPropertyInfo={specialtyPropertyInfo} />
          </Fieldset>
        </div>
      ) : (
        <TabView>
          <TabPanel header="Human Review">
            <HumanReviewSection specialtyPropertyInfo={specialtyPropertyInfo} />
          </TabPanel>
          <TabPanel header="Overall Summary">
            <Accordion>
              <AccordionTab header="TIV/Loss History">
                <TivAndLossHistory
                  specialtyPropertyInfo={specialtyPropertyInfo}
                  totalTIV={totalTIV}
                />
              </AccordionTab>
              <AccordionTab header="TIV Summary (State by State)">
                <StateTivTable specialtyPropertyInfo={specialtyPropertyInfo} />
              </AccordionTab>
              <AccordionTab header="CAT Events">
                <CatEvents totalTIV={totalTIV} />
              </AccordionTab>
              <AccordionTab header="Layers By Year">
                <LayersByYear specialtyPropertyInfo={specialtyPropertyInfo} />
              </AccordionTab>
            </Accordion>
          </TabPanel>
        </TabView>
      )}
    </Card>
  );
};

const QUESTION_IDS_TO_QUESTIONS: { [key: string]: string } = {
  sov_1: "Are the correct excel sheets used?",
  sov_2:
    "Do the column mappings from the original sheet to rms fields look correct?",
  sov_3: "Do the occupany codes look correct for their descriptions?",
  sov_4: "Do the construction codes look correct for their descriptions?",
  loss_run_1: "Do the year by year summaries look accurate?",
  // Handled with custom logic
  loss_run_2: "",
  general_1: "Does the appetite classification look accurate?",
  general_2: "Does the NAICS code look accurate?",

  // Handled with custom logic
  layers_1: "",
  layers_2: "",
};

const QUESTION_IDS_TO_TAB_PATHS: { [key: string]: string } = {
  sov_1: "sov/sheets",
  sov_2: "sov/columns",
  sov_3: "sov/occupancy",
  sov_4: "sov/construction",
  loss_run_1: "loss-run",
  loss_run_2: "loss-run",
  layers_1: "layers",
  layers_2: "layers",
};

const SECTION_IDS_TO_TITLES: { [key: string]: string } = {
  general: "General",
  sov: "SOV",
  loss_run: "Loss Run",
  layers: "Layers",
};

const hasAdditionalContentForQuestion = (
  questionId: string,
  specialtyPropertyInfo: SafeReportResponse
) => {
  if (questionId === "general_1") {
    return specialtyPropertyInfo.report_json.business_classification
      ?.llm_reasoning;
  }
  return new Set(["general_2", "loss_run_2", "sov_1"]).has(questionId);
};

const questionTextForQuestionId = (
  questionId: string,
  specialtyPropertyInfo: SafeReportResponse
) => {
  // If we don't know about the question, show nothing
  if (!(questionId in QUESTION_IDS_TO_QUESTIONS)) {
    return "";
  }

  // A few special cases
  if (questionId === "layers_1") {
    const sortedLayers = sortedLayersFromReport(specialtyPropertyInfo);
    const hasLayersThatOverlap = hasOverlappingLayers(sortedLayers);
    if (hasLayersThatOverlap) {
      return "Does the layer structure look accurate? We have detected overlapping layers.";
    } else {
      return "Does the layer structure look accurate?";
    }
  }

  if (questionId === "layers_2") {
    const sortedLayers = sortedLayersFromReport(specialtyPropertyInfo);
    const layersWithoutTargets = sortedLayers.filter(
      (layer) => layer.target === null
    ).length;
    if (layersWithoutTargets === 1) {
      return "1 layer was found without a target premium. Is it fine to proceed?";
    } else if (layersWithoutTargets > 1) {
      return `${layersWithoutTargets} layers were found without target premiums. Is it fine to proceed?`;
    } else {
      return "";
    }
  }

  if (questionId === "loss_run_2") {
    const errors = lossRunErrorsForReport(specialtyPropertyInfo);
    if (errors.length === 1) {
      return `There was 1 parsing error in the Loss Run. Is it fine to proceed?`;
    } else if (errors.length > 1) {
      return `There were ${errors.length} parsing errors in the Loss Run. Is it fine to proceed?`;
    } else {
      return "";
    }
  }

  // The default case
  return QUESTION_IDS_TO_QUESTIONS[questionId];
};

const keyOrder = ["general", "sov", "loss_run", "layers"];

const Excerpt = ({ text }: { text: string }) => {
  return (
    <div className={styles.excerptContainer}>
      <div className={styles.verticalDivider} />
      <div className={styles.policyExcerpt}>{text}</div>
    </div>
  );
};

const AdditionalContentForQuestion = ({
  questionId,
  specialtyPropertyInfo,
}: {
  questionId: string;
  specialtyPropertyInfo: SafeReportResponse;
}) => {
  // Industry
  if (
    questionId === "general_1" &&
    specialtyPropertyInfo.report_json.business_classification?.llm_reasoning
  ) {
    return (
      <Excerpt
        text={
          specialtyPropertyInfo.report_json.business_classification
            ?.llm_reasoning
        }
      />
    );
  }

  // NAICS code
  if (questionId === "general_2") {
    return (
      <Excerpt
        text={specialtyPropertyInfo.report_json.naics_info.description}
      />
    );
  }

  if (questionId === "loss_run_2") {
    const errors = lossRunErrorsForReport(specialtyPropertyInfo);
    return (
      <div className={styles.verticalContainerSmallGap}>
        {errors.map((error, index) => (
          <div key={index}>
            <Message
              severity="warn"
              className={styles.customMessage}
              content={<span>{`Failed to parse value: ${error.value}`}</span>}
            />
          </div>
        ))}
      </div>
    );
  }

  if (questionId === "sov_1") {
    return (
      <div className={styles.verticalContainerSmallGap}>
        {specialtyPropertyInfo.report_json.sovs.map((sov) => (
          <div className={styles.warningHorizontalContainer}>
            <Checkbox
              checked={sov.is_enabled}
              disabled
              pt={{
                input: { style: { width: "16px", height: "16px" } },
                root: { style: { width: "16px", height: "16px" } },
              }}
            />
            <span
              style={{ fontSize: "12px", color: "var(--text-color-secondary)" }}
            >
              {sov.filename}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return <></>;
};

const HumanReviewSection = ({
  specialtyPropertyInfo,
}: {
  specialtyPropertyInfo: SafeReportResponse;
}) => {
  const humanReviewInfo = specialtyPropertyInfo.human_review_info;
  const sectionKeys = Object.keys(humanReviewInfo.review_by_section).sort(
    (a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b)
  );

  return (
    <div>
      {sectionKeys.map((sectionKey) => {
        const humanQuestions = humanReviewInfo.review_by_section[sectionKey];
        if (!(sectionKey in SECTION_IDS_TO_TITLES)) {
          return <></>;
        }
        return (
          <div key={sectionKey}>
            <Card
              title={SECTION_IDS_TO_TITLES[sectionKey]}
              className={styles.perilCard}
            >
              <div className={styles.humanReviewItemsContainer}>
                {humanQuestions.map((question) => (
                  <HumanReviewRow
                    question={question}
                    key={question.question_id}
                    specialtyPropertyInfo={specialtyPropertyInfo}
                  />
                ))}
              </div>
            </Card>
            {sectionKey !== "layers" && <Divider />}
          </div>
        );
      })}
    </div>
  );
};

const AvatarWithTimestamp = ({
  author,
  ts,
}: {
  author: client.User;
  ts: string;
}) => {
  const date = new Date(ts);
  const formatter = new Intl.DateTimeFormat("en", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });
  return (
    <div className={styles.warningHorizontalContainer}>
      <PantheonAvatar label={displayNameForUser(author)} />
      <span
        style={{
          fontSize: "12px",
          color: "var(--text-color-secondary)",
          flexShrink: "0",
        }}
      >
        {formatter.format(date)}
      </span>
    </div>
  );
};

const HumanReviewRow = ({
  question,
  specialtyPropertyInfo,
}: {
  question: client.HumanReviewQuestion;
  specialtyPropertyInfo: SafeReportResponse;
}) => {
  const updateReport = useUpdateReport(specialtyPropertyInfo.report_id);
  const [isSavingWarningMessage, setIsSavingWarningMessage] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    question.warning_message && question.warning_message !== ""
  );
  const [isEditingWarningMessage, setIsEditingWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState(
    question.warning_message
  );
  useEffect(() => {
    setWarningMessage(question.warning_message);
  }, [question.warning_message]);

  const companyId = useContext(CompanyContext);
  const localUser = useUsers(companyId)?.data?.current_user;
  const author = useUserById(question.last_updated_user_id);
  const canExpandRow =
    (question.warning_message && question.warning_message !== "") ||
    question.status !== "none" ||
    hasAdditionalContentForQuestion(
      question.question_id,
      specialtyPropertyInfo
    );

  const navigate = useNavigate();

  const questionText = questionTextForQuestionId(
    question.question_id,
    specialtyPropertyInfo
  );

  if (questionText === "") {
    return <></>;
  }

  return (
    <div className={styles.humanReviewItem}>
      <div className={styles.humanReviewItemRow}>
        <HumanReviewButton
          isSavingWarningMessage={isSavingWarningMessage}
          question={question}
          reportId={specialtyPropertyInfo.report_id}
          localUserId={localUser?.id}
        />
        <span style={{ fontWeight: "bold" }}>{questionText}</span>
        <div className={styles.buttonGroup}>
          {canExpandRow && (
            <Button
              icon={isExpanded ? "pi pi-angle-down" : "pi pi-angle-up"}
              text
              size="small"
              className={styles.smallButton}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          )}
          <Button
            icon="pi pi-pencil"
            text
            size="small"
            className={styles.smallButton}
            onClick={() => setIsEditingWarningMessage(!isEditingWarningMessage)}
          />
          {question.question_id in QUESTION_IDS_TO_TAB_PATHS && (
            <Button
              icon="pi pi-external-link"
              size="small"
              text
              className={styles.smallButton}
              onClick={() =>
                navigate(
                  ROUTES.DASHBOARD_REPORT(
                    specialtyPropertyInfo.report_id,
                    QUESTION_IDS_TO_TAB_PATHS[question.question_id]
                  )
                )
              }
            />
          )}
        </div>
      </div>
      {((canExpandRow && isExpanded) || isEditingWarningMessage) && (
        <div
          className={styles.warningVerticalContainer}
          style={{ marginLeft: "30px", marginBottom: "8px" }}
        >
          {hasAdditionalContentForQuestion(
            question.question_id,
            specialtyPropertyInfo
          ) && (
            <AdditionalContentForQuestion
              questionId={question.question_id}
              specialtyPropertyInfo={specialtyPropertyInfo}
            />
          )}
          {(question.warning_message ||
            question.status === "success" ||
            isEditingWarningMessage) && (
            <div className={styles.verticalContainerSmallGap}>
              <div>
                <Message
                  className={styles.customMessage}
                  severity={
                    question.warning_message || isEditingWarningMessage
                      ? "info"
                      : "success"
                  }
                  content={
                    !isEditingWarningMessage ? (
                      <div className={styles.warningHorizontalContainer}>
                        <span>{warningMessage || "Looks good to me."}</span>
                      </div>
                    ) : (
                      <div className={styles.warningVerticalContainer}>
                        <InputTextarea
                          disabled={isSavingWarningMessage}
                          value={warningMessage ?? ""}
                          onChange={(e) => setWarningMessage(e.target.value)}
                          rows={2}
                          cols={40}
                        />
                        <div
                          className={styles.warningHorizontalContainer}
                          style={{ justifyContent: "flex-end" }}
                        >
                          <Button
                            size="small"
                            label="Cancel"
                            disabled={isSavingWarningMessage}
                            onClick={() => {
                              setWarningMessage(question.warning_message);
                              setIsEditingWarningMessage(false);
                            }}
                            severity="secondary"
                            raised
                            style={{ padding: "6px 8px" }}
                          />
                          <Button
                            size="small"
                            label="Save"
                            loading={isSavingWarningMessage}
                            raised
                            style={{ padding: "6px 8px" }}
                            disabled={warningMessage === null}
                            onClick={async () => {
                              setIsSavingWarningMessage(true);
                              const newQuestion = {
                                ...question,
                                warning_message: warningMessage,
                                last_updated_ts: new Date().toISOString(),
                                last_updated_user_id: localUser?.id
                                  ? localUser.id
                                  : null,
                              };
                              await updateReport.mutateAsync({
                                humanReviewQuestion: newQuestion,
                              });
                              setIsEditingWarningMessage(false);
                              setIsExpanded(true);
                              setIsSavingWarningMessage(false);
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                />
              </div>
              {!isEditingWarningMessage &&
                author &&
                question.last_updated_ts && (
                  <AvatarWithTimestamp
                    ts={question.last_updated_ts}
                    author={author}
                  />
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const HumanReviewButton = ({
  reportId,
  question,
  localUserId,
  isSavingWarningMessage,
}: {
  reportId: string;
  question: client.HumanReviewQuestion;
  localUserId: string | undefined;
  isSavingWarningMessage: boolean;
}) => {
  const options = [{ value: "success", icon: "pi pi-check" }];
  const updateReport = useUpdateReport(reportId);
  const [isSavingCheckState, setIsSavingCheckState] = useState(false);

  return (
    <>
      {isSavingCheckState ? (
        <MultiStateCheckbox
          value="spin"
          options={[{ value: "spin", icon: "pi pi-spin pi-spinner" }]}
          optionValue="value"
        />
      ) : (
        <MultiStateCheckbox
          disabled={isSavingWarningMessage}
          value={question.status}
          onChange={async (e) => {
            let newStatus = e.value;
            if (newStatus !== "success") {
              newStatus = "none";
            }
            const newQuestion = {
              ...question,
              status: newStatus,
              last_updated_ts: new Date().toISOString(),
              last_updated_user_id: localUserId ? localUserId : null,
            };
            setIsSavingCheckState(true);
            await updateReport.mutateAsync({
              humanReviewQuestion: newQuestion,
            });
            setIsSavingCheckState(false);
          }}
          options={options}
          optionValue="value"
        />
      )}
    </>
  );
};

const LayersByYear = ({
  specialtyPropertyInfo,
}: {
  specialtyPropertyInfo: SafeReportResponse;
}) => {
  const currentYear = new Date().getFullYear();
  const yearsBack = [0, 1, 2, 3, 4];
  const layersForSingleYear = sortedLayersFromReport(
    specialtyPropertyInfo
  ).reverse();
  const addresses = useMemo(
    () =>
      specialtyPropertyInfo.report_json.sovs
        .filter((sov) => sov.is_enabled)
        .flatMap((sov) => sov.addresses),
    [specialtyPropertyInfo]
  );

  const allLayers: {
    startingPoint: number;
    range: number;
    target: number | null;
    rated: number | null;
    year: number;
    tiv: number;
    layerNumber: number;
  }[] = [];
  for (const yearBack of yearsBack) {
    let count = 0;
    for (const layer of layersForSingleYear) {
      let tivForLayer = 0;
      for (const address of addresses) {
        if (address.tiv > layer.startingPoint) {
          tivForLayer += Math.min(
            layer.range,
            address.tiv - layer.startingPoint
          );
        }
      }
      const newLayer = {
        ...layer,
        target: layer.target ? layer.target * 0.96 ** yearBack : null,
        year: currentYear - yearBack,
        layerNumber: count,
        tiv: tivForLayer * 0.95 ** yearBack,
      };
      allLayers.push(newLayer);
      count += 1;
    }
  }
  return (
    <DataTable value={allLayers}>
      <Column header="Year" field="year" />
      <Column header="Layer Number" field="layerNumber" />
      <Column
        header="Layer"
        body={(layer) =>
          `${formatAbbreviatedMoney(
            layer.startingPoint
          )}-${formatAbbreviatedMoney(layer.startingPoint + layer.range)}`
        }
      />
      <Column
        header="Premium"
        body={(layer) =>
          layer.target ? formatAbbreviatedMoney(layer.target) : "None Found"
        }
      />
      <Column
        header="TIV"
        body={(layer) => formatAbbreviatedMoney(layer.tiv)}
      />
      <Column
        header="TIV / Prem"
        body={(layer) =>
          layer.target && layer.target !== 0
            ? (layer.tiv / layer.target).toFixed(2)
            : "No Premium Found"
        }
      />
      <Column
        header="Rate Change"
        body={(layer) => {
          const layerOneYearAgo = allLayers.find(
            (otherLayer) =>
              otherLayer.year === layer.year - 1 &&
              otherLayer.layerNumber === layer.layerNumber
          );
          if (
            layerOneYearAgo &&
            layerOneYearAgo.target &&
            layerOneYearAgo.target !== 0 &&
            layer.target &&
            layer.target !== 0
          ) {
            const ratioNow = layer.tiv / layer.target;
            const ratioLastYear = layerOneYearAgo.tiv / layerOneYearAgo.target;
            const newerIsBigger = ratioNow >= ratioLastYear;
            const rateChange = (
              (newerIsBigger
                ? (ratioNow - ratioLastYear) / ratioNow
                : (ratioLastYear - ratioNow) / ratioLastYear) * 100
            ).toFixed(2);
            return newerIsBigger ? `-${rateChange} %` : `+${rateChange} %`;
          }

          return "";
        }}
      />
    </DataTable>
  );
};

const CatEvents = ({ totalTIV }: { totalTIV: number }) => {
  const exampleInfo = [
    {
      frequency: "AAL",
      namedWind: 0.00076470588 * 0.6 * totalTIV,
      caEq: 0.00004352941 * 0.6 * totalTIV,
      pnwEq: 0.00076470588 * 0.6 * 0.025 * totalTIV,
      nmEq: 0.00076470588 * 0.6 * 0.0125 * totalTIV,
    },
    {
      frequency: "1/25",
      namedWind: 0.00076470588 * totalTIV,
      caEq: 0.00004352941 * totalTIV,
      pnwEq: 0,
      nmEq: 0,
    },
    {
      frequency: "1/100",
      namedWind: 0.00252941176 * totalTIV,
      caEq: 0.0009117647 * totalTIV,
      pnwEq: 0,
      nmEq: 0,
    },
    {
      frequency: "1/250",
      namedWind: 0.00382352941 * totalTIV,
      caEq: 0.00176470588 * totalTIV,
      pnwEq: 0.00014705882 * totalTIV,
      nmEq: 0,
    },
  ];
  return (
    <DataTable value={exampleInfo}>
      <Column header="Frequency" field="frequency" />
      <Column
        header="Named Wind"
        body={(data) => formatAbbreviatedMoney(data.namedWind)}
      />
      <Column
        header="CA EQ"
        body={(data) => formatAbbreviatedMoney(data.caEq)}
      />
      <Column
        header="PNW EQ"
        body={(data) => formatAbbreviatedMoney(data.pnwEq)}
      />
      <Column
        header="NM EQ"
        body={(data) => formatAbbreviatedMoney(data.nmEq)}
      />
    </DataTable>
  );
};

const TivAndLossHistory = ({
  specialtyPropertyInfo,
  totalTIV,
}: {
  specialtyPropertyInfo: SafeReportResponse;
  totalTIV: number;
}) => {
  let lossRuns =
    specialtyPropertyInfo.report_json.loss_run.computed_loss_runs_by_year
      .length > 0
      ? specialtyPropertyInfo.report_json.loss_run.computed_loss_runs_by_year
      : specialtyPropertyInfo.report_json.loss_run.parsed_loss_runs_by_year;
  lossRuns = reverse(sortBy(lossRuns, "policy_year"));

  return (
    <DataTable value={lossRuns}>
      <Column sortable header="Year" field="policy_year"></Column>
      <Column
        field="gross_loss_paid"
        header="Ground Up Loss"
        body={(data: client.LossRunByYear) => (
          <TerribleMoney
            dollars={data.gross_loss_paid.value}
            fieldName="Ground Up Loss"
            errors={data.gross_loss_paid.errors}
          />
        )}
      />
      <Column
        header="TIV"
        body={(data: client.LossRunByYear) => {
          const numYearsAgo = getNumYearsAgo(data.policy_year);
          const tiv = numYearsAgo ? totalTIV * 0.95 ** numYearsAgo : 0;
          return <TerribleMoney dollars={tiv} fieldName="TIV" />;
        }}
      />
      <Column
        header="Loss/TIV"
        body={(data: client.LossRunByYear) => {
          const numYearsAgo = getNumYearsAgo(data.policy_year);
          const tiv = numYearsAgo ? totalTIV * 0.95 ** numYearsAgo : 0;
          const ratio = tiv === 0 ? 0 : data.gross_loss_paid.value / tiv;
          return ratio.toFixed(5);
        }}
      />
    </DataTable>
  );
};

const StateTivTable = ({
  specialtyPropertyInfo,
}: {
  specialtyPropertyInfo: SafeReportResponse;
}) => {
  const currentYear = new Date().getFullYear();
  const yearsBack = [0, 1, 2, 3, 4];

  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  const tivByStates = useMemo(() => {
    const stateToTiv: { [key: string]: number } = {};
    for (const sov of specialtyPropertyInfo.report_json.sovs) {
      if (sov.is_enabled) {
        for (const address of sov.addresses) {
          if (address.state && !(address.state in stateToTiv)) {
            stateToTiv[address.state] = 0;
          }
          if (address.state) {
            stateToTiv[address.state] += address.tiv;
          }
        }
      }
    }
    return stateToTiv;
  }, [specialtyPropertyInfo]);

  return (
    <DataTable value={Object.entries(tivByStates)}>
      <Column header="State" field="0"></Column>
      {yearsBack.map((yearBack) => (
        <Column
          header={currentYear - yearBack}
          field="1"
          key={yearBack}
          body={([state, tiv]) => {
            let newValue = tiv * 0.95 ** yearBack;

            // Special case for florida for demo
            if (state === "FL" && yearBack !== 0) {
              newValue = tiv * 0.6 * 0.95 ** yearBack;
            }

            // TODO: highlight big percentage changes in a non-hardcoded way
            const shouldHighlight =
              state === "FL" && (yearBack === 0 || yearBack === 1);

            return (
              <div>
                <span
                  style={{
                    backgroundColor: shouldHighlight
                      ? isLightMode
                        ? "var(--yellow-100)"
                        : "var(--yellow-900)"
                      : undefined,
                    padding: "2px",
                  }}
                >
                  {formatAbbreviatedMoney(newValue)}
                </span>
              </div>
            );
          }}
        ></Column>
      ))}
    </DataTable>
  );
};
